import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import { handleInsiderMenuOpen } from 'client/engagement-handlers/global-navigation-engagement-handler/global-navigation-engagement-handler';
import { ANIMATION_DURATION } from 'site-modules/shared/utils/collapse-utils';

// Navigation for xs and sm screens

const NAVIGATION_PREVENT_CLOSE_CLASS = 'global-navigation-prevent-close';

export function GlobalNavigationMobileWrapper({ children }) {
  const [isOpen, toggleNav] = useToggle(false);
  const [setOpenTimeout] = useTimeout();

  useEffect(() => {
    const handleClickOutside = event => {
      const { target } = event;
      if (target.closest(`.${NAVIGATION_PREVENT_CLOSE_CLASS}`)) {
        return;
      }
      toggleNav();
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
      handleInsiderMenuOpen(true); // isMobile = true
      requestAnimationFrame(() => {
        setOpenTimeout(() => {
          document.body.classList.add('modal-open', 'mobile-nav-menu-open');
        }, ANIMATION_DURATION);
      });
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('click', handleClickOutside);
        requestAnimationFrame(() => {
          setOpenTimeout(() => {
            document.body.classList.remove('modal-open', 'mobile-nav-menu-open');
          }, ANIMATION_DURATION);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Fragment>
      {React.cloneElement(children, {
        isOpen,
        toggleNav,
        // We can't pass "ref" prop to the cloned element children in the simple way
        // so it is better to use className instead
        // https://github.com/facebook/react/issues/8873
        preventCloseClassName: NAVIGATION_PREVENT_CLOSE_CLASS,
      })}
    </Fragment>
  );
}

GlobalNavigationMobileWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
