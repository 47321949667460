import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, omit } from 'lodash';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { signIn, signInFirebase } from 'client/actions/profile';
import { onAuthStateChanged } from './firebase-auth';

const OMIT_FROM_PROPS = ['WrappedComponent', 'options', 'parentProps'];

export function ProfileAuth(props) {
  const { isAuthenticated, WrappedComponent, parentProps, options } = props;

  useEffect(() => {
    onAuthStateChanged(user => {
      if (user) {
        props.signIn(user.isAnonymous);
      } else {
        props.signInFirebase();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (options.renderWhenInitialized && isAuthenticated === null) {
    return null;
  }

  const omittedProps = omit(props, OMIT_FROM_PROPS);
  const wrappedProps = {
    ...omittedProps,
    ...parentProps,
  };

  return <WrappedComponent isAuthenticated={isAuthenticated} {...wrappedProps} />;
}

ProfileAuth.propTypes = {
  signInFirebase: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  WrappedComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType,
  ]).isRequired,
  options: PropTypes.shape({
    renderWhenInitialized: PropTypes.bool,
  }),
  parentProps: PropTypes.shape({}),
  isAuthenticated: PropTypes.bool,
};

ProfileAuth.defaultProps = {
  isAuthenticated: null,
  options: {},
  parentProps: {},
};

export const mapStateToProps = state => ({
  isAuthenticated: get(state, 'profile.isAuthenticated'),
});

export const mapDispatchToProps = dispatch => ({
  signIn: isAnonymousUser => dispatch(signIn(isAnonymousUser)),
  signInFirebase: () => dispatch(signInFirebase()),
});

/**
 * Initializes firebase and applies the correct onAuthChange listener with the props connected needed for ProfileAuth.
 * NOTE: This auto populates the required props for ProfileAuth component
 */
const ConnectedProfileAuthWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(connectToModel(ProfileAuth, {}));

export function connectedProfileAuth(component, options) {
  ConnectedProfileAuthWrapper.displayName = `ConnectedProfileAuthWrapper(${component.name})`;
  return props => <ConnectedProfileAuthWrapper WrappedComponent={component} options={options} parentProps={props} />;
}
