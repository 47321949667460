import { each } from 'lodash';

/**
 * Adds script to body.
 *
 * @param src {string}
 * @param [dataset] {object}
 * @param [id] {string}
 * @example injectScript(src, { camelCase: 'value' }) -> <script async src=src data-camel-case="value" id=id></script>
 *
 */
export const injectScript = (src, dataset = {}, id) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;

    if (id) script.id = id;

    each(dataset, (dataValue, dataName) => {
      script.dataset[dataName] = dataValue;
    });

    document.body.appendChild(script);
  });
