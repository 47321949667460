import { useRef, useState, useCallback, useEffect } from 'react';

export function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const callback = useRef(null);

  const setStateCallback = useCallback((newState, cb) => {
    callback.current = cb;
    setState(newState);
  }, []);

  useEffect(() => {
    if (callback.current) {
      callback.current(state);
      callback.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}
