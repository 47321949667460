import PropTypes from 'prop-types';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { sample, random } from 'client/utils/seed-randomizers';

const HERO_IMAGES_MAP = {
  car_reviews_landing: [
    {
      mobile: '/img/landing-hero/car_reviews_landing_S.jpg',
      desktop: '/img/landing-hero/car_reviews_landing_L_1.jpg',
    },
    {
      mobile: '/img/landing-hero/car_reviews_landing_S_2.jpg',
      desktop: '/img/landing-hero/car_reviews_landing_L_1_2.jpg',
    },
    {
      mobile: '/img/landing-hero/car_reviews_landing_S_3.jpg',
      desktop: '/img/landing-hero/car_reviews_landing_L_1_3.jpg',
    },
  ],
};

const HeroImage = PropTypes.shape({
  mobile: PropTypes.string,
  desktop: PropTypes.string,
});

const TransactionsCountAdjustments = PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number));

export const RandomPropTypes = {
  HeroImage,
  TransactionsCountAdjustments,
};

function getHeroImagePath(page) {
  return `heroImage["${page}"]`;
}

function getTransactionsCountAdjustments(count) {
  return count > 1 ? `transactionsCountAdjustments["${count}"]` : null;
}

function getPageSurveyRecipePath() {
  return 'pageSurveyRecipe';
}

export const RandomPaths = {
  getHeroImagePath,
  getTransactionsCountAdjustments,
  getPageSurveyRecipePath,
};

/**
 * This model is used to randomize server-side content without any API calls
 */
export const RandomModel = createModelSegment('random', [
  /**
   * @see getHeroImagePath
   */
  {
    path: 'heroImage["{page}"]',
    resolve({ page }) {
      return Promise.resolve(sample(HERO_IMAGES_MAP[page] || []));
    },
  },
  /**
   * Creates adjustments from -0.2 to 0.2
   * @see getTransactionsCountAdjustments
   */
  {
    path: 'transactionsCountAdjustments["{count}"]',
    resolve({ count }) {
      return Promise.resolve(
        new Array(4)
          .fill()
          .map(() => new Array(parseInt(count, 10)).fill().map(() => parseFloat((random() * 0.4 - 0.2).toFixed(2))))
      );
    },
  },
  /**
   * @see getPageSurveyRecipePath
   */
  {
    path: 'pageSurveyRecipe',
    resolve() {
      const randomPercent = random() * 100;

      switch (true) {
        case randomPercent < 1: {
          return Promise.resolve('chal-mktg');
        }
        case randomPercent >= 1 && randomPercent < 3: {
          return Promise.resolve('chal-brandassoc');
        }
        case randomPercent >= 3 && randomPercent < 5: {
          return Promise.resolve('chal-csat');
        }
        default: {
          return Promise.resolve('ctrl');
        }
      }
    },
  },
]);
