import React from 'react';
import PropTypes from 'prop-types';

/**
 * This component was created because the 'react-scroll' Element component is problematic for keyboard accessibility.
 * This component works in combination with ScrollLink (a replacement for the 'react-scroll' Link component)
 * to manage focus and support keyboard accessibility.
 */
export function ScrollElement({ children, tag: Tag, innerRef, ...elementProps }) {
  return (
    // Tabindex of -1 allows us to call .focus() on an element that would not receive focus by default (a div in this case).
    // TODO: Remove name once all 'react-scroll' Links are replaced with ScrollLink
    <Tag tabIndex={-1} name={elementProps.id} ref={innerRef} {...elementProps}>
      {children}
    </Tag>
  );
}

ScrollElement.propTypes = {
  id: PropTypes.string.isRequired,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  innerRef: PropTypes.shape(),
};

ScrollElement.defaultProps = {
  tag: 'div',
  innerRef: null,
};
