import React from 'react';
import Container from 'reactstrap/lib/Container';

export function ErrorPageMessage() {
  return (
    <Container className="my-3">
      <h2 className="text-center display-1 error-page-message">we&apos;ve had a minor breakdown.</h2>
    </Container>
  );
}
