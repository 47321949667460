import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { SIGN_EMAIL_ERROR } from 'site-modules/shared/components/profile/smart-sign/helper';

const INVALID_LOGIN_CREDENTIALS =
  'Login failed. Please make sure you have entered the correct email and password and your email is not attached to a social login.';
const FIREBASE_ERROR_MAP = {
  'auth/invalid-login-credentials': INVALID_LOGIN_CREDENTIALS,
  'auth/wrong-password': INVALID_LOGIN_CREDENTIALS,
  'auth/weak-password': 'Your password must be at least 7 characters.',
  'auth/email-already-in-use': 'An account has already been created with this email.',
  'auth/operation-not-allowed': 'Internal login error. Please try again later.',
  'auth/user-not-found': 'There is no account associated with this email.',
  'auth/invalid-email': 'Email address is not valid.',
  'auth/popup-closed-by-user': 'The popup has been closed before finalizing the operation.',
  'auth/network-request-failed': 'A network error occurred.',
  'auth/internal-error': 'Internal Error.',
  'auth/expired-action-code': 'Action code has expired.',
  'auth/invalid-action-code': 'Action code is invalid.',
  'auth/user-disabled': 'Account has been disabled.',
  'auth/account-exists-with-different-credential': 'Account with the same email address already exists.',
  'auth/auth-domain-config-required': 'Auth domain configuration is not provided.',
  'auth/cancelled-popup-request': 'Only one popup request is allowed at one time.',
  'auth/operation-not-supported-in-this-environment':
    'Operation is not supported in the environment your application is running on.',
  'auth/popup-blocked': 'Popup was blocked by the browser.',
  'auth/unauthorized-domain': 'App domain is not authorized for OAuth operations.',
  'auth/credential-already-in-use': 'This credential is already associated with a different user account.',
  'auth/provider-already-linked': 'The provider is already linked.',
  'auth/unknown': 'An unknown error occurred.',
  'auth/invalid-action-code_EMAILVERIFY':
    'Your verification link is invalid or has expired. Please click the button below to receive a new link via email.',
  'auth/too-many-requests':
    'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later',
  'custom/smart-sign-social-only': 'Your email is attached to a social login. Please continue with your social login.',
  'verify/missing-input-secret': 'The secret parameter is missing.',
  'verify/invalid-input-secret': 'The secret parameter is invalid or malformed.',
  'verify/missing-input-response': 'The response parameter is missing.',
  'verify/invalid-input-response': 'The response parameter is invalid or malformed.',
  'verify/bad-request': 'The request is invalid or malformed.',
  'verify/timeout-or-duplicate': 'The response is no longer valid: either is too old or has been used previously.',
  'verify/try-later': 'The request is invalid or malformed. Please try again later.',
};

const FIREBASE_FORGOT_PASSWORD_ERRORS = [
  'auth/invalid-login-credentials',
  'auth/wrong-password',
  'auth/too-many-requests',
];
const BTN_CLASS_NAME = 'text-primary-darker text-transform-none p-0 font-weight-normal';

export function FirebaseErrors({ error, toggleSignModal, toggleForgotPassword, customErrorMap }) {
  const errorMap = {
    ...FIREBASE_ERROR_MAP,
    ...customErrorMap,
  };

  const errorCode = error.code || error.message;
  const err = errorMap[errorCode];

  if (!err) {
    return <li>{errorMap['auth/unknown']}</li>;
  }

  return (
    <li>
      {err}
      {errorCode === SIGN_EMAIL_ERROR && toggleSignModal && (
        <Fragment>
          {' '}
          <Button
            color="link"
            className={BTN_CLASS_NAME}
            onClick={toggleSignModal}
            data-tracking-id="begin_sign_in"
            data-no-refresh
          >
            Please sign in to your account.
          </Button>
        </Fragment>
      )}
      {FIREBASE_FORGOT_PASSWORD_ERRORS.includes(errorCode) && toggleForgotPassword && (
        <Fragment>
          {' '}
          <Button
            color="link"
            className={BTN_CLASS_NAME}
            onClick={toggleForgotPassword}
            data-tracking-id="recover_password"
            data-no-refresh
          >
            Forgot your password?
          </Button>
        </Fragment>
      )}
      {errorCode === 'auth/user-not-found' && toggleSignModal && (
        <Fragment>
          Please click{' '}
          <Button
            color="link"
            className={BTN_CLASS_NAME}
            onClick={toggleSignModal}
            data-tracking-id="begin_sign_up"
            data-no-refresh
          >
            Sign up
          </Button>
        </Fragment>
      )}
    </li>
  );
}

FirebaseErrors.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  toggleSignModal: PropTypes.func,
  toggleForgotPassword: PropTypes.func,
  customErrorMap: PropTypes.shape({}),
};

FirebaseErrors.defaultProps = {
  toggleSignModal: null,
  toggleForgotPassword: null,
  customErrorMap: {},
};
