import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { Link } from 'site-modules/shared/components/link/link';

import './insider-empty-state.scss';

export function InsiderEmptyState({
  title,
  heading,
  subTitle,
  trackingId,
  buttonText,
  buttonHref,
  buttonClassName,
  iconClass,
  className,
}) {
  return (
    <Fragment>
      {title && <h2 className="size-24 font-weight-bold mb-1_5 text-gray-darker">{title}</h2>}
      <div className={classnames('models-compare-and-read-wrapper px-0 rounded text-center', className)}>
        <div className="icon-container rounded-circle d-inline-block p-1 text-gray mb-0_75">
          <span className={iconClass} />
        </div>
        {heading && <h3 className="heading-5">{heading}</h3>}
        <p className="px-2 text-gray-darker">{subTitle}</p>
        <Button
          tag={Link}
          to={buttonHref}
          size="sm"
          color="outline-primary-b"
          data-tracking-id={trackingId}
          className={classnames('car-reviews-btn py-0_25', buttonClassName)}
          rel="nofollow"
        >
          <span className="size-16 font-weight-medium">{buttonText}</span>
        </Button>
      </div>
    </Fragment>
  );
}

InsiderEmptyState.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  trackingId: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonHref: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  iconClass: PropTypes.string,
  className: PropTypes.string,
};

InsiderEmptyState.defaultProps = {
  title: '',
  heading: '',
  buttonText: 'Find great deals near you',
  buttonClassName: '',
  iconClass: 'icon-heart4',
  className: 'bg-gray-extra-light py-2 py-md-2_5',
};
