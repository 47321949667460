import React, { Component, Fragment } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { UserProfileModel } from 'client/data/models/profile/profile';

import { INSIDER_PATHS } from 'site-modules/shared/constants/profile/dashboard-paths';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { Link } from 'site-modules/shared/components/link/link';
import { InsiderEmptyState } from 'site-modules/shared/components/profile/insider-empty-state/insider-empty-state';
import {
  ALERT_TYPES_MAPPING,
  getTransparentPricing,
  markAlertAsViewed,
} from 'client/site-modules/shared/components/profile/price-drop-alerts/alerts-utils';
import { insiderMethods } from 'site-modules/shared/components/profile/insider-methods';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { getVdpUrl } from 'site-modules/shared/utils/inventory-utils/get-vdp-url';
import { handleInsiderMenuOpen } from 'client/engagement-handlers/global-navigation-engagement-handler/global-navigation-engagement-handler';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { stripHttp } from 'client/utils/string-utils';
import { NO_VEHICLE_GRAY_IMAGE_SEDAN } from 'site-modules/shared/constants/images';

import './price-drop-alerts-list.scss';

const UNFILTERED_SRP_LINK = srpLinkBuilder();

const CREATIVE_ID = 'notifications_menu';
const CREATIVE_ID_EMPTY = 'notifications_menu_empty';
const getPhotoPath = (size = 'large') => `photo.defaultPhoto.${size}.url`;
const getPhoto = vehicleInfo => get(vehicleInfo, getPhotoPath(), get(vehicleInfo, getPhotoPath('large'), ''));
const noVehicleImage = getStaticImageUrl(NO_VEHICLE_GRAY_IMAGE_SEDAN);

const ALERT_TYPE_TITLE_MAPPING = {
  [ALERT_TYPES_MAPPING.PRICE_DROP[0]]: 'Saved car',
  [ALERT_TYPES_MAPPING.VIEWED_VIN_PRICE_DROP[0]]: 'Viewed car',
};

const TRACKING_ID_MAPPING = {
  [ALERT_TYPES_MAPPING.PRICE_DROP[0]]: 'view_price_drop_details',
  [ALERT_TYPES_MAPPING.VIEWED_VIN_PRICE_DROP[0]]: 'view_viewed_vin_price_drop_details',
};

export class PriceDropAlertsListComponent extends Component {
  static propTypes = {
    priceDropAlerts: PropTypes.shape({
      lastAlertsWithInventory: PropTypes.arrayOf(PropTypes.shape()),
      alertsWithInventory: PropTypes.arrayOf(PropTypes.shape()),
    }),
    updateIdmData: PropTypes.func.isRequired,
    showAlerts: PropTypes.bool,
  };

  static defaultProps = {
    priceDropAlerts: {},
    showAlerts: false,
  };

  componentWillUnmount() {
    const {
      priceDropAlerts: { alertsWithInventory },
      updateIdmData,
      showAlerts,
    } = this.props;
    const unViewedAlerts = markAlertAsViewed(alertsWithInventory);

    if (showAlerts && unViewedAlerts.alerts.length) {
      updateIdmData(unViewedAlerts);
    }
  }

  renderPriceDropItem = alert => {
    const {
      alertViewedTs,
      alertData: { priceDrop },
      inventoryData,
    } = alert;
    const { vin, vehicleInfo } = inventoryData;
    const {
      styleInfo: { year, make, model },
    } = vehicleInfo;
    const parsedPriceDrop = parseInt(priceDrop, 10);
    const imageSrc = stripHttp(getPhoto(vehicleInfo)) || noVehicleImage;
    const vehicleWithImage = imageSrc !== noVehicleImage;
    const { transparentPrice } = getTransparentPricing(inventoryData);

    return (
      <li className="my-1" key={vin}>
        <Link
          className="text-decoration-none alerts-list-item rounded-8 d-block"
          to={getVdpUrl(inventoryData)}
          data-tracking-id={TRACKING_ID_MAPPING[alert.alertType]}
          data-tracking-ignore-value
        >
          <Row className="no-gutters p-1">
            <Col xs={3} className={classnames({ 'pt-0_25': vehicleWithImage, 'pt-1_25': !vehicleWithImage })}>
              <img className="w-100 rounded" src={imageSrc} alt="" />
            </Col>
            <Col xs={9} className="pl-1">
              <div className="mb-0 medium">{ALERT_TYPE_TITLE_MAPPING[alert.alertType]} price drop!</div>
              <div className="text-primary-darker medium">
                <div>
                  {year} {make} {model}
                </div>
                <div>
                  <span className="font-weight-bold mt-0_25">New price: {formatPriceString(transparentPrice)}</span>
                  <span
                    className={classnames(
                      'price-drop-container d-inline-block font-weight-bold ml-0_5 px-0_25 rounded',
                      {
                        'bg-gray-lighter text-info': alertViewedTs,
                        'bg-warning text-white': !alertViewedTs,
                      }
                    )}
                  >
                    <div className="price-drop-icon d-inline-block">
                      <span className="icon-triangle2 align-middle" aria-hidden />
                    </div>{' '}
                    {formatPriceString(Math.floor(parsedPriceDrop))}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Link>
      </li>
    );
  };

  renderAlertsList = lastAlertsWithInventory => {
    handleInsiderMenuOpen(false, { creative_id: CREATIVE_ID });

    return (
      <div className="price-drop-alerts-list text-gray-darker" data-tracking-parent={CREATIVE_ID}>
        <ul className="list-unstyled" aria-labelledby="price-drop-header">
          {lastAlertsWithInventory.map(alert => this.renderPriceDropItem(alert))}
        </ul>
        <div className="mt-1_5">
          <Link
            className="text-decoration-none text-primary-darker body"
            to={INSIDER_PATHS.INVENTORY_PAGE}
            color="link"
            data-tracking-id="view_content"
          >
            See all notifications <span className="icon-arrow-right4" aria-hidden />
          </Link>
        </div>
      </div>
    );
  };

  renderEmptySection = () => {
    handleInsiderMenuOpen(false, { creative_id: CREATIVE_ID_EMPTY });

    return (
      <div data-tracking-parent={CREATIVE_ID_EMPTY}>
        <InsiderEmptyState
          buttonHref={UNFILTERED_SRP_LINK}
          heading="No new notifications!"
          subTitle="Save cars and searches for price drop alerts and new inventory matches."
          className="bg-white pt-1_5 pb-1"
          buttonClassName="find-deals-button py-0_5"
          trackingId="add_vehicle"
        />
      </div>
    );
  };

  renderAlertsSection = priceDropAlerts => {
    const { lastAlertsWithInventory } = priceDropAlerts;
    if (!priceDropAlerts || !lastAlertsWithInventory) {
      return this.renderSpinner();
    }
    if (lastAlertsWithInventory && !lastAlertsWithInventory.length) {
      return this.renderEmptySection();
    }
    return this.renderAlertsList(lastAlertsWithInventory);
  };

  renderSpinner = () => (
    <div className="spinner-container justify-content-center d-flex">
      <Spinner size={48} thickness={2} color="info" className="mr-0_5 align-self-center" />
    </div>
  );

  render() {
    const { showAlerts, priceDropAlerts } = this.props;

    return (
      <Fragment>
        <div id="price-drop-header" className="heading-4 mb-0">
          Notifications
        </div>
        {showAlerts ? this.renderAlertsSection(priceDropAlerts) : this.renderEmptySection()}
      </Fragment>
    );
  }
}

export const stateToPropsConfig = {
  priceDropAlerts: bindToPath(
    ({ showAlerts }) => (showAlerts ? 'data.alerts.type["ALL"].withInventory' : ''),
    UserProfileModel
  ),
};

export const PriceDropAlertsList = connectToModel(insiderMethods(PriceDropAlertsListComponent), stateToPropsConfig);
