import { isDealerSRP } from 'site-modules/shared/utils/inventory/srp-type-checkers';

const EXCLUDE_APPRAISAL_LINK_SRP_PAGE_NAME = 'new_used_car_inventory_srp';
const EXCLUDE_APPRAISAL_LINK_PAGE_NAMES = [
  'car_inventory_ddp_map',
  'car_inventory_ddp_map_instant_offer',
  'car_buying_personal',
];
const EXCLUDE_VDP_PAGE = 'inventory_vin_detail';

export const getIsAppraisalLinkExcluded = (pageName, selectedFacets) => {
  const isDealerSRPExcluded = pageName === EXCLUDE_APPRAISAL_LINK_SRP_PAGE_NAME && isDealerSRP(selectedFacets);
  const isVDPPage = !!pageName?.includes(EXCLUDE_VDP_PAGE);
  return isDealerSRPExcluded || EXCLUDE_APPRAISAL_LINK_PAGE_NAMES.includes(pageName) || isVDPPage;
};
