import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { VisitorModel } from 'client/data/models/visitor';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { HomeVehicleSearch } from 'site-modules/shared/components/home-vehicle-search/home-vehicle-search';
import { getPlaceholderText } from 'site-modules/shared/components/home-vehicle-search/search-box-placeholder';

import './search-box.scss';

const ADDON_CLASSES = {
  opened: 'search-box-icon icon-cross3 text-info px-0_75 py-1 bg-white border-0 p-md-0',
  closed: 'search-box-icon icon-search px-0_75 py-1 bg-white border-0 p-md-0',
  submitted: 'search-box-icon text-info bg-white border-0 d-flex justify-content-center align-items-center',
};

const CREATIVE_ID = 'global-nav-autocomplete';

function SearchBoxUI({
  className,
  closeClassName,
  onDropDownOpen,
  onDropDownClose,
  onCrossClick,
  segment,
  pathname,
  isMobile,
}) {
  const placeholder = getPlaceholderText(segment);

  return (
    <HomeVehicleSearch
      trackingCreativeId={CREATIVE_ID}
      wrapperClasses={classnames('autocomplete-search', className)}
      closeClassName={closeClassName}
      searchBoxClasses="autocomplete-search-box w-100"
      searchInputClasses="pl-0_25 pr-1 py-0_5"
      addonClasses={ADDON_CLASSES}
      placeholder={placeholder}
      onDropDownOpen={onDropDownOpen}
      onDropDownClose={onDropDownClose}
      onCrossClick={onCrossClick}
      pathname={pathname}
      isMobile={isMobile}
    />
  );
}

SearchBoxUI.propTypes = {
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  onDropDownOpen: PropTypes.func,
  onDropDownClose: PropTypes.func,
  onCrossClick: PropTypes.func,
  segment: PropTypes.shape({}),
  pathname: PropTypes.string,
  isMobile: PropTypes.bool,
};

SearchBoxUI.defaultProps = {
  className: '',
  closeClassName: '',
  onDropDownOpen: null,
  onDropDownClose: null,
  onCrossClick: null,
  segment: null,
  pathname: '',
  isMobile: false,
};

const stateToPropsConfig = {
  segment: bindToPath('segment', VisitorModel, null, false),
};

export const SearchBox = connectToModel(React.memo(SearchBoxUI), stateToPropsConfig);
