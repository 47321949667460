export const SELECTION_TYPE = {
  NEW: 'make model new',
  USED: 'make model used',
  PRE_PROD: 'make model preprod',
  MAKE: 'make',
  TYPE_RANKING: 'type ranking',
  TYPE_USED: 'type used',
  SITEMAP: 'sitemap',
  NEW_TRIM: 'make model trim new',
  USED_TRIM: 'make model trim used',
  PRE_PROD_TRIM: 'make model trim preprod',
};

export const DATA_TYPE = {
  STATIC: 'static',
  STATIC_LINK: 'static-link',
  SEARCH_HISTORY: 'search-history',
  LABEL: 'label',
  MAKE_LINK: 'make-link',
  MULTIPLE_SUGGEST: 'multiple-suggest',
  NOT_FOUND: 'not-found',
};
