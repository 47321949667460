import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

export function firebaseValidation(WrappedComponent) {
  function FirebaseValidation(props) {
    const [firebaseError, setFirebaseError] = useState(null);

    const parse = useCallback(error => setFirebaseError(error), []);

    return <WrappedComponent firebaseError={firebaseError} parseFirebaseError={parse} {...props} />;
  }

  return connect(state => ({
    disableRecaptcha: state.featureFlags['disable-recaptcha'],
  }))(FirebaseValidation);
}
