import React from 'react';

import './skip-to-main-content.scss';

export function SkipToMainContent() {
  return (
    // position: absolute inline style is needed to try out potential fix of the problem
    // with unknown browser scroll happening in SpeedCurve on first paint https://edmunds.atlassian.net/browse/SHOP-1765
    <a href="#main-content" className="sr-only skip-to-main-link" style={{ position: 'absolute' }}>
      Skip to main content
    </a>
  );
}
