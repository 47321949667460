import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { REFRESH_ZONES } from 'site-modules/shared/constants/ads';
import { VehicleEntities } from 'client/data/models/vehicle';
import { LeaderboardNative } from 'site-modules/shared/components/native-ad/leaderboard/leaderboard-native';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

const CPA_PAGES_NO_SPOT = new Set([
  'model_core',
  'used_model_core',
  'model_core_mpg',
  'model_car_inventory_vin_detail',
]);

/*
'model_core':                           /subaru/outback/2020/
'used_model_core':                      /honda/accord/2015/sedan/
'used_model_core_review':               /subaru/outback/2015/review/
'model_core_review':                    /subaru/outback/2020/review/
'model_core_feature_specs':             /subaru/outback/{2015|2020}/features-specs/
'model_core_mpg':                       /subaru/outback/{2015|2020}/mpg/
'model_core_tco':                       /subaru/outback/{2015|2020}/cost-to-own/
'model_core_pictures':                  /subaru/outback/{2015|2020}/pictures/
'model_write_consumer_review':          /subaru/outback/{2015|2020}/write-review/
'model_core_consumer_reviews':          /subaru/outback/{2015|2020}/consumer-reviews/
'used_model_mydp_tmv_appraiser_style':  /subaru/outback/{2015|2020}/appraisal-value/
*/
const CPA_PAGES_NO_MAB_NEW = new Set([
  'model_core',
  'model_core_review',
  'model_core_feature_specs',
  'model_core_mpg',
  'model_core_tco',
  'model_core_pictures',
  'model_write_consumer_review',
  'model_core_consumer_reviews',
  'used_model_mydp_tmv_appraiser_style',
]);
const CPA_PAGES_NO_MAB_USED = new Set([
  'used_model_core',
  'model_core_pictures',
  'model_write_consumer_review',
  'used_model_mydp_tmv_appraiser_style',
]);

const PAGES_WITH_DROP_DOWN_MENU = new Set([
  'new_cars_index',
  'used_cars_index',
  'car_reviews_index',
  'cpo_programs_cars_index',
]);
const PAGES_WITH_PHOTOFLIPPER_MODAL = new Set(['make_index']);

const PAGES_WITH_LAZY_CONTENT_COLLAPSE = new Set(['new_type_index']);

export function matchAdvicePageName(pageName) {
  return pageName.match(/(advice_)[a-z-]+(_article)/g);
}

export function StickyBannerContent({
  page,
  trkParam,
  refreshZones,
  noRefresh,
  vehicle,
  adUnitRenderListener,
  prefixId,
  isMobile,
}) {
  const publicationState = get(vehicle, 'publicationState', 'new');
  const pageName = get(page, 'name', '');
  const isSpotDisable = CPA_PAGES_NO_SPOT.has(pageName);
  const isMabDisable =
    (publicationState === 'new' && CPA_PAGES_NO_MAB_NEW.has(pageName)) ||
    (publicationState === 'used' && CPA_PAGES_NO_MAB_USED.has(pageName));
  const isUsedVdpPage = publicationState === 'used' && pageName === 'model_car_inventory_vin_detail';
  const isNoMenuRefreshEnabled = PAGES_WITH_DROP_DOWN_MENU.has(pageName);
  const isNoStickyBannerRefreshEnabled =
    PAGES_WITH_PHOTOFLIPPER_MODAL.has(pageName) ||
    matchAdvicePageName(pageName) ||
    PAGES_WITH_LAZY_CONTENT_COLLAPSE.has(pageName);
  const noRefreshAds =
    isNoMenuRefreshEnabled || isNoStickyBannerRefreshEnabled
      ? Array.from(new Set([...noRefresh, 'engagement']))
      : noRefresh;

  return (
    <div className="adhesion-wrapper">
      {!isMobile && (
        <div className="adhesion-units-container">
          <div className="leaderboard">
            <LeaderboardNative
              slotRenderEndListener={adUnitRenderListener}
              wiredBreakpoints={{ md: true, lg: true, xl: true }}
              customTargeting={trkParam}
              dfpCollapse
              noRefresh={noRefreshAds}
              refreshZones={refreshZones}
              prefixId={prefixId}
            />
          </div>
          {(isUsedVdpPage || !isSpotDisable) && (
            <div className="spot hidden-md-down">
              <AdUnit
                adName="spot"
                position={'0'}
                lg
                xl
                renderWhenViewable={false}
                slotRenderEndListener={adUnitRenderListener}
                refreshZones={refreshZones}
                customTargeting={trkParam}
                dfpCollapse
                noRefresh={noRefreshAds}
                customSizes={[[1, 1], [300, 90]]}
                prefixId={prefixId}
              />
            </div>
          )}
        </div>
      )}
      {isMobile && !isMabDisable && (
        <div className="mab adhesion-units-container m-auto">
          <AdUnit
            adName="mab"
            position={'1'}
            xs
            sm
            renderWhenViewable={false}
            slotRenderEndListener={adUnitRenderListener}
            refreshZones={refreshZones}
            customTargeting={trkParam}
            dfpCollapse
            noRefresh={noRefreshAds}
            prefixId={prefixId}
          />
        </div>
      )}
    </div>
  );
}

StickyBannerContent.propTypes = {
  page: PropTypes.shape({
    name: PropTypes.string,
  }),
  trkParam: PropTypes.shape({
    trk: PropTypes.string,
  }),
  refreshZones: PropTypes.arrayOf(PropTypes.string),
  noRefresh: PropTypes.arrayOf(PropTypes.string),
  vehicle: VehicleEntities.Vehicle,
  adUnitRenderListener: PropTypes.func.isRequired,
  prefixId: PropTypes.string,
  isMobile: PropTypes.bool,
};

StickyBannerContent.defaultProps = {
  page: {},
  trkParam: {},
  refreshZones: [REFRESH_ZONES.ADHESION],
  noRefresh: ['scroll'],
  vehicle: null,
  prefixId: undefined,
  isMobile: false,
};
