import { DATA_TYPE } from 'site-modules/shared/constants/home-vehicle-search-constants';
import { LANDING_PATH } from 'site-modules/shared/constants/car-buying';

const dataType = DATA_TYPE.STATIC;

const commonPopularSearchData = {
  selectionType: 'popular search',
  dataType,
  lastYear: true,
};

const VOLVO_S60 = {
  autoSuggestValue: 'Volvo S60',
  autoSuggestValueFull: 'Volvo S60',
  make: 'Volvo',
  model: 'S60',
  ...commonPopularSearchData,
};
const CHEVROLET_SILVERADO_1500 = {
  autoSuggestValue: 'Silverado 1500',
  autoSuggestValueFull: 'Silverado 1500',
  make: 'chevrolet',
  model: 'silverado-1500',
  ...commonPopularSearchData,
};
const APPRAISER_TOOL = {
  autoSuggestValue: 'Car Appraiser Tool',
  autoSuggestValueFull: 'Car Appraiser Tool',
  url: '/appraisal/',
  ...commonPopularSearchData,
};
const HONDA_CRV = {
  autoSuggestValue: 'Honda CR-V',
  autoSuggestValueFull: 'Honda CR-V',
  make: 'Honda',
  model: 'CR-V',
  ...commonPopularSearchData,
};
const LEASE_DEALS_SEARCH = {
  autoSuggestValue: 'Lease Deals',
  autoSuggestValueFull: 'Lease Deals',
  url: LANDING_PATH,
  ...commonPopularSearchData,
};

export const POPULAR_SEARCHES = [VOLVO_S60, CHEVROLET_SILVERADO_1500, APPRAISER_TOOL, HONDA_CRV, LEASE_DEALS_SEARCH];

export const NO_SEARCH_RESULTS = [
  {
    autoSuggestValue: 'No matches found, try searching for make or model name only',
    autoSuggestValueFull: 'No matches found, try searching for make or model name only',
    dataType: DATA_TYPE.NOT_FOUND,
  },
];
