export const SEGMENT_TYPE_PLACEHOLDER = {
  DEFAULT: 'Civic',
  sedan: 'Accord',
  suv: 'XC40',
  crossover: 'Passport',
  minivan: 'Odyssey',
  hatchback: 'C40',
  luxury: 'RX',
  convertible: 'MX-5 Miata',
  truck: 'F-150',
  coupe: 'Mustang',
  wagon: 'V90',
  hybrid: 'Sportage',
  van: 'Sprinter',
};

/**
 * Gets placeholder text. Logic
 * finds the first existing specified value from SEGMENT_TYPE_PLACEHOLDER map
 * DEFAULT: SEGMENT_TYPE_PLACEHOLDER.DEFAULT
 * @param {Object} segment - udm segment
 * @param {Boolean} modelOnly - returns model only
 */
export function getPlaceholderText(segment, modelOnly = false) {
  const segmentTypes = (segment && segment.types) || []; // handles types of null
  const firstAvailableSegmentType = segmentTypes.find(segmentType => SEGMENT_TYPE_PLACEHOLDER[segmentType]);
  const model = SEGMENT_TYPE_PLACEHOLDER[firstAvailableSegmentType] || SEGMENT_TYPE_PLACEHOLDER.DEFAULT;
  return modelOnly ? model : `Try "${model}"`;
}
