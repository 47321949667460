/* eslint-disable camelcase */
import { get, isNil, pick } from 'lodash';
import {
  getMakeModelLinkUrl,
  getMakeModelYearLinkUrl,
  getMakeModelYearReviewLinkUrl,
} from 'site-modules/shared/utils/vehicle-link-constructor';
import {
  generateSrpLinkMakeModel,
  generateSrpLinkUsedType,
  srpLinkBuilder,
  INVENTORY_PUB_STATES,
} from 'site-modules/shared/utils/srp-link-constructor';
import { getTypeUrl } from 'client/site-modules/shared/utils/type-link-constructor';
import { makeNiceName } from 'client/site-modules/shared/utils/nice-name';
import { DATA_TYPE, SELECTION_TYPE } from 'client/site-modules/shared/constants/home-vehicle-search-constants';
import { CORE_SRP_URL_PATTERN } from 'site-modules/shared/constants/inventory/srp-url-patterns';

/**
 * replace everything but a-z, \d, \s, \w, ., -, with the empty string
 * @param {String} queryString
 * @returns {String} modified queryString
 */
export const transformSearchQuery = queryString => {
  let prettifiedQuery;
  // replace everything but a-z, \d, \s, \w, -, ., with the empty string
  prettifiedQuery = queryString.replace(/[^a-z\d\s\w-.]+/gi, '');
  // replace '.' with the UTF-8 encoding ('%2E') so it doesn't interfere with the venom model path
  prettifiedQuery = prettifiedQuery.replace(/\./gi, '%2E');

  const transformedQuery = prettifiedQuery;
  return transformedQuery
    .split(' ')
    .filter(el => el)
    .join(',');
};

/**
 * Get the autocomplete autoSuggestValue test, this is for the multi selection per auto suggest.
 * @param {string} [value]
 * @returns {string}
 */
const getNewPricingReviewText = value => (value ? `${value} in New Pricing & Reviews` : 'New Pricing & Reviews');
const getNewInventoryText = value => (value ? `${value} in New Inventory` : 'New Inventory');
const getUsedInventoryText = value => (value ? `${value} Used Inventory` : 'Used Inventory');
const getReviewSpecsText = value => (value ? `${value} in Reviews & Specs` : 'Reviews & Specs');
const getRankingsText = value => `Best ${value} Rankings`;

/**
 * Generates the comment auto suggest value for auto complete options.
 * @returns {Object}
 */
const autoSuggestValueGenerator = {
  newPricingReviews: autoSuggestValue => ({
    autoSuggestValue: getNewPricingReviewText(),
    autoSuggestValueFull: getNewPricingReviewText(autoSuggestValue),
  }),
  newInventory: autoSuggestValue => ({
    autoSuggestValue: getNewInventoryText(),
    autoSuggestValueFull: getNewInventoryText(autoSuggestValue),
  }),
  usedInventory: autoSuggestValue => ({
    autoSuggestValue: getUsedInventoryText(),
    autoSuggestValueFull: getUsedInventoryText(autoSuggestValue),
  }),
  reviewSpecs: autoSuggestValue => ({
    autoSuggestValue: getReviewSpecsText(),
    autoSuggestValueFull: getReviewSpecsText(autoSuggestValue),
  }),
  rankings: autoSuggestValue => ({
    autoSuggestValue: getRankingsText(autoSuggestValue),
    autoSuggestValueFull: getRankingsText(autoSuggestValue),
  }),
  generic: autoSuggestValue => ({
    autoSuggestValue,
    autoSuggestValueFull: autoSuggestValue,
  }),
};

/**
 * MEM-3158 Year Threshold. This is the threshold for current year minus 1.
 */
const getYearThreshold = () => new Date().getFullYear() - 1;

const getLastProductionYear = publicationStates => [
  ...get(publicationStates, 'NEW', []),
  ...get(publicationStates, 'USED', []),
  ...get(publicationStates, 'NEW_USED', []),
];

/**
 * MEM-3158 Business Logic. To pull the new or used year (single value year case) and returns that value to generated the MMY url
 * @param {Object} autoSuggest
 */
export const getYearValue = ({ new_years = [], used_years = [], publicationStates = {} }) => {
  const preprod_years = get(publicationStates, 'PRE_PROD', []);
  const lastProduction_year = getLastProductionYear(publicationStates);
  const NEW_YEARS_EMPTY = new_years.length === 0;
  const USED_YEARS_EMPTY = used_years.length === 0;
  const PREPROD_YEARS_IS_SINGLE = preprod_years.length === 1;
  const NEW_YEARS_IS_SINGLE = new_years.length === 1;
  const USED_YEARS_IS_SINGLE = used_years.length === 1;
  const LAST_PRODUCTION_YEAR_IS_SINGLE = lastProduction_year.length === 1;
  const USED_YEARS_IS_SINGLE_OR_DOES_NOT_EXIST = used_years.length < 2;
  if (NEW_YEARS_EMPTY && USED_YEARS_EMPTY) {
    if (PREPROD_YEARS_IS_SINGLE) return preprod_years[0];
    if (LAST_PRODUCTION_YEAR_IS_SINGLE) return lastProduction_year[0];
  }
  if (NEW_YEARS_IS_SINGLE && USED_YEARS_IS_SINGLE_OR_DOES_NOT_EXIST) return new_years[0];
  if (USED_YEARS_IS_SINGLE && NEW_YEARS_EMPTY) return used_years[0];

  return null;
};

/**
 * Appends 'year' to the selection type only if it's a single value year.
 * @param {Object} autoSuggest
 * @param {String} selectionType
 */
const determineYearSelectionType = (autoSuggest, selectionType) => {
  const type = autoSuggest.trim ? `${selectionType}_TRIM` : selectionType;

  return getYearValue(autoSuggest) ? `year ${SELECTION_TYPE[type]}` : SELECTION_TYPE[type];
};

/**
 * This filters out the search that has the dataType of "label". We do not want to include this in the search logic.
 * dataType "label" is only used for display purposes.
 * @param searches
 * @returns {Object}
 */
export const searchFilteredWithoutLabel = searches => searches.filter(search => DATA_TYPE.LABEL !== search.dataType);

/**
 * MEM-3158 Edge Case. It looks like we have a lot of New 2017 inventory but the models are no longer consider new,
 * so the new pricing and reviews links point to used SRP
 * @param {Object} autoSuggest
 */
const forceUsedOnly = ({ new_years = [] }) => new_years.length === 1 && parseInt(new_years[0], 10) < getYearThreshold();

/**
 * Generates auto suggested string by pattern [ 'model' ]
 * @returns {Array} modified autoSuggestData
 */
export const generateMultipleAutoSuggest = ({
  makemodel: makeModel = [],
  fuzzy_makemodel: fuzzyMakeModel = [],
  sitemap: siteMaps = [],
  bodytype: bodyTypes = [],
  makemodelyeartrim: makeModelTrim = [],
  fuzzy_makemodelyeartrim: fuzzyMakeModelTrim = [],
}) => {
  let searchIndex = -1;

  const pattern = ['make', 'model', 'trim', 'type'];
  const multipleAutoSuggests = [];
  const multipleSuggest = {
    newUsed: (common, autoSuggestValue, isPubStatesNewYearsOnly) => {
      multipleAutoSuggests.push({
        ...common,
        ...autoSuggestValueGenerator.newPricingReviews(autoSuggestValue),
        selectionType: determineYearSelectionType(common, 'NEW'),
        index: (searchIndex += 1),
        withSpeculation: true,
      });
      multipleAutoSuggests.push({
        ...common,
        ...autoSuggestValueGenerator.usedInventory(autoSuggestValue),
        linkType: 'USED_SRP',
        selectionType: determineYearSelectionType(common, 'USED'),
        index: (searchIndex += 1),
      });

      if (!isPubStatesNewYearsOnly) {
        multipleAutoSuggests.push({
          ...common,
          ...autoSuggestValueGenerator.newInventory(autoSuggestValue),
          linkType: 'NEW_SRP',
          selectionType: determineYearSelectionType(common, 'NEW'),
          index: (searchIndex += 1),
        });
      }
    },
    newOnly: (common, autoSuggestValue, isPubStatesNewYearsOnly) => {
      multipleAutoSuggests.push({
        ...common,
        ...autoSuggestValueGenerator.newPricingReviews(autoSuggestValue),
        selectionType: determineYearSelectionType(common, 'NEW'),
        index: (searchIndex += 1),
        withSpeculation: true,
      });

      if (!isPubStatesNewYearsOnly) {
        multipleAutoSuggests.push({
          ...common,
          ...autoSuggestValueGenerator.newInventory(autoSuggestValue),
          linkType: 'NEW_SRP',
          selectionType: determineYearSelectionType(common, 'NEW'),
          index: (searchIndex += 1),
        });
      }
    },
    usedOnly: (common, autoSuggestValue) => {
      const { used_years = [], preprod_years = [] } = common;
      const PREPROD_YEARS_EMPTY = preprod_years.length === 0;
      const USED_YEARS_IS_SINGLE = used_years.length === 1 && PREPROD_YEARS_EMPTY;

      if (USED_YEARS_IS_SINGLE) {
        multipleAutoSuggests.push({
          ...common,
          ...autoSuggestValueGenerator.usedInventory(autoSuggestValue),
          selectionType: determineYearSelectionType(common, 'USED'),
          index: (searchIndex += 1),
        });
      } else {
        multipleAutoSuggests.push(
          {
            ...common,
            ...autoSuggestValueGenerator.usedInventory(autoSuggestValue),
            selectionType: determineYearSelectionType(common, 'USED'),
            index: (searchIndex += 1),
          },
          {
            ...common,
            ...autoSuggestValueGenerator.reviewSpecs(autoSuggestValue),
            linkType: PREPROD_YEARS_EMPTY ? 'MAKE_MODEL_REVIEW' : 'MAKE_MODEL',
            selectionType: determineYearSelectionType(common, 'USED'),
            index: (searchIndex += 1),
          }
        );
      }
    },
    type: (common, autoSuggestValue) => {
      multipleAutoSuggests.push(
        {
          ...common,
          ...autoSuggestValueGenerator.rankings(autoSuggestValue),
          linkType: 'TYPE_NEW',
          selectionType: SELECTION_TYPE.TYPE_RANKING,
          index: (searchIndex += 1),
        },
        {
          ...common,
          ...autoSuggestValueGenerator.usedInventory(autoSuggestValue),
          linkType: 'TYPE_USED',
          selectionType: SELECTION_TYPE.TYPE_USED,
          index: (searchIndex += 1),
        }
      );
    },
    preProd: (common, autoSuggestValue, isUsed) => {
      multipleAutoSuggests.push({
        ...common,
        ...autoSuggestValueGenerator.reviewSpecs(autoSuggestValue),
        linkType: isUsed ? 'MAKE_MODEL_REVIEW' : 'MAKE_MODEL',
        selectionType: isUsed
          ? determineYearSelectionType(common, 'USED')
          : determineYearSelectionType(common, 'PRE_PROD'),
        index: (searchIndex += 1),
      });
    },
    preProdLastProd: (common, autoSuggestValue) => {
      multipleAutoSuggests.push(
        {
          ...common,
          ...autoSuggestValueGenerator.reviewSpecs(autoSuggestValue),
          linkType: 'MAKE_MODEL_NO_YEAR',
          selectionType: determineYearSelectionType(common, SELECTION_TYPE.PRE_PROD),
          index: (searchIndex += 1),
        },
        {
          ...common,
          ...autoSuggestValueGenerator.newPricingReviews(autoSuggestValue),
          linkType: 'MAKE_MODEL_NO_YEAR',
          selectionType: determineYearSelectionType(common, SELECTION_TYPE.PRE_PROD),
          index: (searchIndex += 1),
          withSpeculation: true,
        }
      );
    },
  };

  const makeModelTrimWithFuzzy = [...makeModelTrim, ...fuzzyMakeModelTrim];
  const makeModelWithFuzzy = [...makeModel, ...fuzzyMakeModel, ...makeModelTrimWithFuzzy];
  const autoCompleteData = [...bodyTypes, ...makeModelWithFuzzy];
  autoCompleteData.forEach(dataObj => {
    const vehicle = get(dataObj, 'vehicle', {});
    const publicationStates = get(vehicle, 'publicationStates', {});
    const inventory = get(dataObj, 'inventory', {});
    const inventoryNewYears = get(inventory, 'new_years');
    const pubStatesNewYears = get(publicationStates, 'NEW') || get(publicationStates, 'NEW_USED');
    const isPubStatesNewYearsOnly = !inventoryNewYears && !!pubStatesNewYears;
    const data = {
      ...inventory,
      ...vehicle,
      ...pick(dataObj, ['make', 'model', 'trim', 'type']),
      preprod_years: get(vehicle, 'publicationStates.PRE_PROD'),
      new_years: inventoryNewYears || pubStatesNewYears,
    };
    const { new_years, used_years, preprod_years, type } = data;
    const year = getYearValue(data);
    const commonAutoSuggests = {
      ...data,
      dataType: DATA_TYPE.MULTIPLE_SUGGEST,
    };
    let autoSuggestValue = pattern
      .map(keyName => get(data, keyName, ''))
      .join(' ')
      .trim();
    const hasLastProductionYear = !!getLastProductionYear(publicationStates).length;
    const preprodLastProd =
      preprod_years && hasLastProductionYear && !forceUsedOnly(data) && !new_years && !used_years && !type;

    if (year && !preprodLastProd) {
      autoSuggestValue = `${year} ${autoSuggestValue}`;
    }

    // Needed to generate the labels per auto suggests.
    multipleAutoSuggests.push({
      ...autoSuggestValueGenerator.generic(autoSuggestValue),
      dataType: DATA_TYPE.LABEL,
    });

    if (forceUsedOnly(data)) {
      // MEM-3158 Edge Case
      multipleSuggest.usedOnly({ ...commonAutoSuggests, new_years: [] }, autoSuggestValue);
    } else if (new_years && used_years) {
      multipleSuggest.newUsed(commonAutoSuggests, autoSuggestValue, isPubStatesNewYearsOnly);
    } else if (new_years) {
      multipleSuggest.newOnly(commonAutoSuggests, autoSuggestValue, isPubStatesNewYearsOnly);
    } else if (used_years) {
      multipleSuggest.usedOnly(commonAutoSuggests, autoSuggestValue);
    } else if (type) {
      multipleSuggest.type(commonAutoSuggests, autoSuggestValue);
    } else if (preprod_years && hasLastProductionYear) {
      multipleSuggest.preProdLastProd(commonAutoSuggests, autoSuggestValue);
    } else if (preprod_years || hasLastProductionYear) {
      multipleSuggest.preProd(commonAutoSuggests, autoSuggestValue, hasLastProductionYear);
    }
  });

  if (siteMaps) {
    siteMaps.forEach(siteMap => {
      multipleAutoSuggests.push({
        ...autoSuggestValueGenerator.generic(siteMap.title),
        url: siteMap.url,
        dataType: DATA_TYPE.STATIC_LINK,
        index: (searchIndex += 1),
        selectionType: SELECTION_TYPE.SITEMAP,
      });
    });
  }

  const make = get(makeModelWithFuzzy, '[0].make');
  // Needed to generate see all makes link.
  if (multipleAutoSuggests.length > 1 && make) {
    multipleAutoSuggests.push({
      ...autoSuggestValueGenerator.generic(`${make} all models`),
      make,
      url: `/${makeNiceName(make)}/`,
      dataType: DATA_TYPE.MAKE_LINK,
      index: (searchIndex += 1),
      selectionType: SELECTION_TYPE.MAKE,
    });
  }

  return multipleAutoSuggests;
};

/**
 * Generates the type ranking link
 * @param type
 * @returns {string}
 */
function generateTypeRankingLink(type) {
  return getTypeUrl(
    type
      .toLowerCase()
      .split(' ')
      .join('-')
  );
}

/**
 * Gets all auto suggested strings by field autoSuggestValue (in order to compare prev and next props)
 * @param {Array} autoSuggestData
 * @returns {String} all autoSuggestData values
 */
export const getAutoSuggestValues = autoSuggestData =>
  autoSuggestData.map(dataObj => dataObj.autoSuggestValue || '').toString();

/**
 * Link generator method mappings to build URL easier, based off of business logic.
 * Business logic for URLs: MEM-3324, MEM-3158, MEM-2747, MEM-2520, TRAF-3400
 * @returns {String} generated link
 */
const linkGeneratorMethods = {
  MAKE_MODEL: ({ make, model, new_years = [], used_years = [], publicationStates = {} }) => {
    const vehicleData = {
      makeSlug: makeNiceName(make),
      modelSlug: makeNiceName(model),
    };
    const year = getYearValue({ new_years, used_years, publicationStates });

    if (year) {
      return getMakeModelYearLinkUrl({
        ...vehicleData,
        year,
      });
    }

    return getMakeModelLinkUrl(vehicleData);
  },
  MAKE_MODEL_REVIEW: ({ make, model, used_years = [], publicationStates = {} }) => {
    const vehicleData = {
      makeSlug: makeNiceName(make),
      modelSlug: makeNiceName(model),
    };
    let year = getYearValue({ used_years, publicationStates });
    const usedYears = [...used_years, ...get(publicationStates, 'USED', [])];

    if (!year && usedYears.length) {
      year = Math.max(...used_years, ...get(publicationStates, 'USED', []));
    }

    if (year) {
      return getMakeModelYearReviewLinkUrl({
        ...vehicleData,
        year,
      });
    }

    return getMakeModelLinkUrl(vehicleData);
  },
  USED_SRP: ({ make, model, trim, new_years = [], used_years = [] }) => {
    const year = getYearValue({ new_years, used_years });
    const USED_YEARS_IS_SINGLE = used_years.length === 1;
    const USED_YEARS_IS_MULTIPLE = used_years.length > 1;
    const initialUrlPattern = USED_YEARS_IS_SINGLE && !trim && CORE_SRP_URL_PATTERN;

    if (USED_YEARS_IS_SINGLE || USED_YEARS_IS_MULTIPLE || year) {
      const makeNice = makeNiceName(make);
      const modelNice = makeNiceName(model);
      const trimNice = makeNiceName(trim);

      return srpLinkBuilder({
        inventorytype: INVENTORY_PUB_STATES.USED,
        make: makeNice,
        model: isNil(modelNice) ? undefined : `${makeNice}|${modelNice}`,
        year: USED_YEARS_IS_SINGLE ? used_years[0] : year,
        trim: isNil(trimNice) ? undefined : `${modelNice}|${trimNice}`,
        initialUrlPattern,
      });
    }

    return generateSrpLinkMakeModel(make, model);
  },
  NEW_SRP: ({ make, model, trim, new_years = [] }) => {
    const NEW_YEARS_IS_SINGLE = new_years.length === 1;
    const initialUrlPattern = NEW_YEARS_IS_SINGLE && !trim && CORE_SRP_URL_PATTERN;
    const makeNice = makeNiceName(make);
    const modelNice = makeNiceName(model);
    const trimNice = makeNiceName(trim);

    return srpLinkBuilder({
      inventorytype: INVENTORY_PUB_STATES.NEW,
      make: makeNice,
      model: isNil(modelNice) ? undefined : `${makeNice}|${modelNice}`,
      year: NEW_YEARS_IS_SINGLE ? new_years[0] : null,
      trim: isNil(trimNice) ? undefined : `${modelNice}|${trimNice}`,
      initialUrlPattern,
    });
  },
  TYPE_NEW: ({ type }) => generateTypeRankingLink(type),
  TYPE_USED: ({ type }) => generateSrpLinkUsedType(type),
  MAKE_MODEL_NO_YEAR: ({ make, model }) => {
    const vehicleData = {
      makeSlug: makeNiceName(make),
      modelSlug: makeNiceName(model),
    };

    return getMakeModelLinkUrl(vehicleData);
  },
};

/**
 * Generate link (core / srp / srp used) according to vehicle type and provided data
 * @param {Object} search auto suggested vehicle data
 * @returns {String} generated link
 */
export const generateLink = search => {
  // eslint-disable-next-line no-undef, camelcase
  const { lastYear, new_years = [], url, linkType } = search;

  if (url) {
    return url;
  }

  if (linkType && linkGeneratorMethods[linkType]) {
    return linkGeneratorMethods[linkType](search);
  }

  return new_years.length || lastYear ? linkGeneratorMethods.MAKE_MODEL(search) : linkGeneratorMethods.USED_SRP(search);
};
