import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocationSearch, getLocationPathname, getLocationHash } from './utils';
import './index.scss';

const BODY_ATTRIBUTE_NAME = 'wtf-force-default';

export class ForceDefaultBannerUI extends Component {
  static propTypes = {
    forceDefault: PropTypes.bool,
    hideForceDefaultBanner: PropTypes.bool,
  };

  static defaultProps = {
    forceDefault: false,
    hideForceDefaultBanner: false,
  };

  state = {
    isSSR: true,
  };

  componentDidMount() {
    if (this.props.forceDefault) {
      document.body.setAttribute(`data-${BODY_ATTRIBUTE_NAME}`, 'true');
    }
    // Hydration failed fix
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isSSR: false });
  }

  onClickHandler = () => {
    let queryParamsResult = '?forceDefault=false';
    const locationSearch = getLocationSearch();
    if (locationSearch.indexOf('forceDefault') > 0) {
      queryParamsResult = locationSearch.replace(/forceDefault=true/gi, 'forceDefault=false');
    } else if (locationSearch !== '') {
      queryParamsResult = `${locationSearch}&forceDefault=false`;
    }
    window.open(getLocationPathname() + queryParamsResult + getLocationHash(), '_self');
  };

  render() {
    if (this.state.isSSR) return null;
    const { forceDefault, hideForceDefaultBanner } = this.props;

    return (
      forceDefault &&
      !hideForceDefaultBanner && (
        <div className="force-default-banner">
          <div>
            <i className="icon-info" aria-hidden />
            <span>You are currently opted out of all experiments (except ones forced with fassignment param).</span>
            <span>To opt back in,</span>
            <span role="button" tabIndex={-1} onClick={this.onClickHandler}>
              {' '}
              click here{' '}
            </span>
            <i className="icon-info" aria-hidden />
          </div>
        </div>
      )
    );
  }
}
export const mapStateToProps = state => ({
  forceDefault: state.forceDefault,
  hideForceDefaultBanner: !!state.featureFlags['hide-force-default-banner'],
});

export const ForceDefaultBanner = connect(mapStateToProps)(ForceDefaultBannerUI);
