/**
 * All of EMO's page names.
 *
 * NOTE: Currently OFFER_DETAILS_PAGE only supports "CarMax".
 */
export const PAGE_NAMES = {
  OFFER_RESULTS_NOT_FOUND: 'offer_results_not_found',
  MULTI_OFFER_RESULTS: 'multi_offer_results',
  OFFER_DETAILS_PAGE: 'offer_details_page',
  CARMAX_PRINT_CERTIFICATE_PAGE: 'offer_print_certificate',
};
