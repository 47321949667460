import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Navbar from 'reactstrap/lib/Navbar';
import Col from 'reactstrap/lib/Col';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { LogoSection } from './logo-section';
import { SearchBox } from './search-box';
import { NavigationLinks } from './navigation-links';
import { InsiderSection } from './insider-section';

export const XL_BREAKPOINT = 1200;

// Navigation for tablets, laptops and desktop

export function GlobalNavigationDesktop({ showSearchBar, pathname, isAppraisalLinkExcluded }) {
  const [isOpenSearchDropDown, setOpenSearchDropDown] = useState(false);

  const onSearchDropDownOpen = useCallback(() => {
    if (window.innerWidth < XL_BREAKPOINT) {
      setOpenSearchDropDown(true);
    }
  }, []);

  const onSearchDropDownClose = useCallback(() => {
    if (window.innerWidth < XL_BREAKPOINT) {
      setOpenSearchDropDown(false);
    }
  }, []);

  return (
    <Navbar
      className={classnames(
        'global-navigation-desktop row d-flex flex-row flex-sm-nowrap align-items-center px-0 py-0'
      )}
      aria-label="Main Navigation"
    >
      <Col
        xs={12}
        sm={showSearchBar ? 4 : 3}
        md={showSearchBar ? 4 : 2}
        lg={showSearchBar ? 3 : 2}
        xl={showSearchBar ? 4 : 1}
        className="d-flex align-items-center flex-column flex-sm-row flex-md-row" // to add class to critical css
      >
        {!isOpenSearchDropDown && <LogoSection smallScreen={false} />}
        {showSearchBar && (
          <SearchBox
            className={classnames('mw-100 w-100', { 'pl-sm-0_5 pl-md-1_5': !isOpenSearchDropDown })}
            onDropDownOpen={onSearchDropDownOpen}
            onDropDownClose={onSearchDropDownClose}
            onCrossClick={onSearchDropDownClose}
            pathname={pathname}
            isMobile={false}
          />
        )}
      </Col>
      <Col xs="auto" className="navigation-links-col px-xl-1">
        <NavigationLinks
          className="d-flex flex-row justify-content-around justify-content-md-center align-items-center"
          pathname={pathname}
          renderAppraisalLink={!isAppraisalLinkExcluded}
        />
      </Col>
      <FeatureFlag name="insider">
        <Col xs="auto" className="ml-auto d-flex align-items-center">
          <InsiderSection className="d-flex flex-row flex-nowrap justify-content-end ml-auto" isGlobalNavDesktop />
        </Col>
      </FeatureFlag>
    </Navbar>
  );
}

GlobalNavigationDesktop.propTypes = {
  pathname: PropTypes.string,
  showSearchBar: PropTypes.bool,
  isAppraisalLinkExcluded: PropTypes.bool,
};

GlobalNavigationDesktop.defaultProps = {
  pathname: '',
  showSearchBar: true,
  isAppraisalLinkExcluded: false,
};
