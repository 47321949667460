import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { lazyLoad } from 'site-modules/shared/components/lazy-load/lazy-load';
import { PROFILE_SCREEN } from 'site-modules/shared/components/profile/profile-screen';

const PROFILE_SCREEN_SMART_SIGN_MODALS = [PROFILE_SCREEN.SIGN_MODAL, PROFILE_SCREEN.FORGOT_PASSWORD_MODAL];

const AuthenticatedSmartSignModalAsync = lazyLoad({
  loader: () =>
    import(/* webpackChunkName: "authenticated-smart-sign-modal" */ './authenticated-modal/authenticated-smart-sign-modal'),
  componentName: 'AuthenticatedSmartSignModal',
});

/**
 * ProfileContextAsyncWrapper - This determines whether it should render the profile context
 * lazy load bundle ONLY IF profileScreen is present. This purpose is used so profile-context is NOT LOADED
 * when it's not needed.
 * @param {Object} props
 * props:
 *  - hasProfileScreen {Boolean} - determines whether profile screen is available
 */
function ProfileContextAsyncWrapper({ isOpen }) {
  return isOpen && <AuthenticatedSmartSignModalAsync />;
}

ProfileContextAsyncWrapper.propTypes = {
  isOpen: PropTypes.bool,
};

ProfileContextAsyncWrapper.defaultProps = {
  isOpen: false,
};

const mapStateToProps = state => ({
  isOpen: PROFILE_SCREEN_SMART_SIGN_MODALS.includes(get(state, 'profile.screen')),
});

export const ProfileContextAsync = connect(mapStateToProps)(ProfileContextAsyncWrapper);
