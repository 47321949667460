/**
 * sticky footer that contains ad units
 */
import classnames from 'classnames';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { useStateCallback } from 'site-modules/shared/hooks/use-state-callback';
import { REFRESH_ZONES } from 'site-modules/shared/constants/ads';
import { isRenderedAdUnit } from 'client/utils/ads';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import { VehicleEntities } from 'client/data/models/vehicle';
import { connect } from 'react-redux';
import { AdsModel } from 'client/data/models/ads';
import { bindToContent, CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { AnimatedLeaderboard } from 'site-modules/shared/components/native-ad/animated-leaderboard/animated-leaderboard';

import { StickyBannerContent } from './sticky-banner-content';
import './sticky-banner.scss';

export const ANIMATED_LEADERBOARD_CMS_PATH = 'ads/native/animated-leaderboard';

function isBannerHiddenByPageName(pageName, isMobile) {
  const desktopBlocklist = ['used_model_mydp_tmv_appraiser_style'];
  return !isMobile && desktopBlocklist.includes(pageName);
}

export function StickyBannerUI({
  isMobile,
  page,
  setModelValue,
  animatedLeaderboardAdContent,
  isHomepage,
  ...restProps
}) {
  const [isClosed, setClosed] = useState(false);
  const [hasRenderedAdUnit, setHasRenderedAdUnit] = useStateCallback(false);

  const updateAdhesionBannerModel = useCallback(
    value => {
      setModelValue('adhesionBanner', AdsModel, value);
    },
    [setModelValue]
  );

  const onAdUnitRender = useCallback(
    slotObj => {
      // return if leadearboard or spot is already rendered or not
      if (hasRenderedAdUnit || !isRenderedAdUnit(slotObj)) {
        return;
      }
      setHasRenderedAdUnit(true, () => updateAdhesionBannerModel(true));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasRenderedAdUnit, updateAdhesionBannerModel]
  );

  function closeAdhesion() {
    setClosed(true);
    updateAdhesionBannerModel(false);
  }

  function handleKeyPress(event) {
    if (event.charCode === 13) {
      closeAdhesion();
    }
  }

  const isHideBanner = isBannerHiddenByPageName(page.name, isMobile) || isClosed;

  const isAnimatedLeaderboard = isHomepage && animatedLeaderboardAdContent.hasChild('leaderboard');
  const animatedLeaderboardStyles = isAnimatedLeaderboard
    ? {
        transform: `translateY(calc(100% - ${isMobile ? '23px' : '30px'}))`,
      }
    : null;

  return (
    <div className="fixed-bottom sticky-banner">
      <div id="sticky-tray-portal" className="pos-r sticky-tray-portal" />

      {!isHideBanner && (
        <div
          className={classnames('edm-entry pos-r', {
            'd-none': !hasRenderedAdUnit && !isAnimatedLeaderboard,
            aunit: !isAnimatedLeaderboard,
            'animated-leaderboard-container wired': isAnimatedLeaderboard && !isMobile,
            'animated-leaderboard-container mobile': isAnimatedLeaderboard && isMobile,
          })}
          data-tracking-parent="edm-entry-adhesion-units"
          style={animatedLeaderboardStyles}
        >
          {isAnimatedLeaderboard ? (
            <AnimatedLeaderboard content={animatedLeaderboardAdContent.child('leaderboard')} isMobile={isMobile} />
          ) : (
            <StickyBannerContent {...restProps} page={page} isMobile={isMobile} adUnitRenderListener={onAdUnitRender} />
          )}
          <Button
            onClick={closeAdhesion}
            onKeyPress={handleKeyPress}
            className={classnames('btn-close icon-cross2 close-adhesion', {
              'text-white': !isAnimatedLeaderboard,
            })}
            data-tracking-id="close_adhesion"
            aria-label="Close ads banner"
            style={isAnimatedLeaderboard ? { opacity: 0 } : null}
          />
        </div>
      )}

      <div id="sticky-tray-portal-bottom" className="pos-r sticky-tray-portal" />
    </div>
  );
}

StickyBannerUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  page: PropTypes.shape({
    name: PropTypes.string,
  }),
  trkParam: PropTypes.shape({
    trk: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  refreshZones: PropTypes.arrayOf(PropTypes.string),
  noRefresh: PropTypes.arrayOf(PropTypes.string),
  vehicle: VehicleEntities.Vehicle,
  prefixId: PropTypes.string,
  animatedLeaderboardAdContent: CmsEntities.Content,
  isHomepage: PropTypes.bool,
};

StickyBannerUI.defaultProps = {
  page: {},
  isMobile: false,
  trkParam: {},
  refreshZones: [REFRESH_ZONES.ADHESION],
  noRefresh: ['scroll'],
  vehicle: null,
  prefixId: undefined,
  animatedLeaderboardAdContent: DEFAULT_CONTENT,
  isHomepage: false,
};

const stateToPropsConfig = {
  page: bindToPath('page', PageModel),
  vehicle: bindToPath('vehicle', PageModel),
  animatedLeaderboardAdContent: bindToContent(({ isHomepage }) => isHomepage && ANIMATED_LEADERBOARD_CMS_PATH),
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const StickyBanner = connect(mapStateToProps)(connectToModel(StickyBannerUI, stateToPropsConfig));
