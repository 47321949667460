import React, { Component, Fragment } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { AutoSuggestModel, AutoSuggestEntities, getSearchQueryPath } from 'client/data/models/auto-suggest';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import {
  generateMultipleAutoSuggest,
  getAutoSuggestValues,
  generateLink,
  searchFilteredWithoutLabel,
} from 'site-modules/shared/utils/vehicle-search-utils';
import { DATA_TYPE } from 'site-modules/shared/constants/home-vehicle-search-constants';
import { AutoCompleteOption } from 'site-modules/shared/components/autocomplete-option/autocomplete-option';
import { fireNoSearchResults } from 'client/engagement-handlers/home-engagement-handler/home-engagement-handler';
import { SpeculationRule } from 'site-modules/shared/components/speculation-rule/speculation-rule';
import {
  AutoCompleteOptionHighlightedLabel,
  AutoCompleteOptionLabel,
  NoMatchesFoundLabel,
} from './home-vehicle-search-helper-components';
import { POPULAR_SEARCHES, NO_SEARCH_RESULTS } from './home-vehicle-static-search-data';

export const KEY_CODES = {
  escape: 27,
  enter: 13,
  arrowDown: 40,
  arrowUp: 38,
};

const TOP_RESULT_COUNTS = {
  MULTI: {
    mobile: 2,
    desktop: 3,
  },
};

const DEBOUNCE_TIMEOUT = 650;

const getEmptyStateSearches = searchHistory => {
  if (!isEmpty(searchHistory)) return searchHistory;
  return POPULAR_SEARCHES;
};

export const fireEmptyTracking = (
  prevAutoCompleteOptions,
  nextAutoCompleteOptions,
  searchQuery,
  trackingCreativeId
) => {
  if (prevAutoCompleteOptions === null && nextAutoCompleteOptions && !nextAutoCompleteOptions.length) {
    fireNoSearchResults(searchQuery, trackingCreativeId);
  }
};

export class HomeVehicleSearchAutoCompleteUI extends Component {
  static propTypes = {
    isDropdownOpen: PropTypes.bool.isRequired,
    autoCompleteOptions: AutoSuggestEntities.AutoSuggestSearchResults,
    searchQuery: PropTypes.string,
    closeDropDown: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    asyncStartSearching: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    startSearching: PropTypes.func.isRequired,
    trackingCreativeId: PropTypes.string,
    setModelValue: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    searchHistory: PropTypes.arrayOf(PropTypes.shape({})),
    setFireAbandonSearchTracking: PropTypes.func.isRequired,
    setSearchesTrackingData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    autoCompleteOptions: null,
    searchQuery: '',
    zipCode: '',
    trackingCreativeId: '',
    isMobile: false,
    searchHistory: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { autoCompleteOptions, searchQuery, searchHistory } = nextProps;
    const nextAutoComplete = searchQuery ? autoCompleteOptions : getEmptyStateSearches(searchHistory);
    const currentSearches = prevState.searches;

    const autoCompleteFirstFetched = nextAutoComplete && currentSearches === null;
    const noResults = !autoCompleteFirstFetched && nextAutoComplete && !nextAutoComplete.length;
    const searches = noResults ? NO_SEARCH_RESULTS : nextAutoComplete;
    const autoCompleteValuesChanged =
      searches && currentSearches && getAutoSuggestValues(searches) !== getAutoSuggestValues(currentSearches);

    if (autoCompleteFirstFetched || autoCompleteValuesChanged) {
      nextProps.setSearchesTrackingData(searches);
      return {
        searches,
        isOpen: !!searches,
        showEmptyStateSearch: !searchQuery,
        cursor: -1,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      searches: props.autoCompleteOptions,
      isOpen: false,
      cursor: -1,
      showEmptyStateSearch: false,
    };
    this.fireNoSearchResultsDebounced = debounce(fireEmptyTracking, DEBOUNCE_TIMEOUT);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDownHandler);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.query !== nextState.query) {
      return false;
    }
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const { isSubmitted, cursor, searches } = this.state;
    const {
      autoCompleteOptions: nextAutoCompleteOptions,
      asyncStartSearching,
      searchQuery,
      trackingCreativeId,
    } = this.props;
    const { autoCompleteOptions: prevAutoCompleteOptions } = prevProps;
    const debounceFireAbandonSearch =
      searches.length && searches[0].dataType === DATA_TYPE.NOT_FOUND
        ? this.unsetFireAbandonSearchTrackingDebounce
        : this.setFireAbandonSearchTrackingDebounce;

    if (isSubmitted && cursor > -1) {
      asyncStartSearching.cancel();
      if (nextAutoCompleteOptions || searchQuery === '') {
        const finalSearches = nextAutoCompleteOptions || searches;
        const searchesFiltered = searchFilteredWithoutLabel(finalSearches);
        const search = get(searchesFiltered, cursor, {});
        const canSubmit = searchesFiltered.length && search.dataType !== DATA_TYPE.NOT_FOUND;
        if (canSubmit) {
          this.submit(search, finalSearches);
        }

        this.cancelSubmit();
      }
      return;
    }

    debounceFireAbandonSearch();
    this.fireNoSearchResultsDebounced(
      prevAutoCompleteOptions,
      nextAutoCompleteOptions,
      searchQuery,
      trackingCreativeId
    );

    if (prevProps.asyncStartSearching !== asyncStartSearching) {
      this.setFireAbandonSearchTrackingDebounce.cancel();
      this.unsetFireAbandonSearchTrackingDebounce.cancel();
      this.fireNoSearchResultsDebounced.cancel();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownHandler);
  }

  setFireAbandonSearchTrackingDebounce = debounce(
    () => this.props.setFireAbandonSearchTracking(true),
    DEBOUNCE_TIMEOUT
  );

  unsetFireAbandonSearchTrackingDebounce = debounce(
    () => this.props.setFireAbandonSearchTracking(false),
    DEBOUNCE_TIMEOUT
  );

  cancelSubmit = () => this.setState({ isSubmitted: false });

  keyDownHandler = ({ keyCode, target }) => {
    const isSearchBlockActive = target.closest('.home-vehicle-search-box');
    if (isSearchBlockActive) {
      this.cancelSubmit();

      if (keyCode === KEY_CODES.escape) {
        this.props.closeDropDown();
        return;
      }

      if (keyCode === KEY_CODES.enter) {
        this.handleSearchSubmit(target.value);
        return;
      }

      this.handleArrowKeyClick(keyCode);
    }
  };

  handleSearchSubmit = async searchQuery => {
    const { isMobile, startSearching } = this.props;
    if (searchQuery) {
      await this.props.setModelValue(
        getSearchQueryPath(searchQuery, TOP_RESULT_COUNTS.MULTI, isMobile),
        AutoSuggestModel,
        {
          method: 'CLEAR',
        }
      );
    }

    return this.setState({ isSubmitted: true }, () => {
      startSearching(searchQuery);
    });
  };

  handleArrowKeyClick = keyCode => {
    const { cursor, searches } = this.state;
    const searchesFiltered = searchFilteredWithoutLabel(searches);
    const maxSearchesCursorLength = searchesFiltered && cursor < searchesFiltered.length - 1;

    if (keyCode === KEY_CODES.arrowUp && cursor > 0) {
      this.setState({ cursor: cursor - 1 });
    }

    if (keyCode === KEY_CODES.arrowDown && maxSearchesCursorLength) {
      this.setState({ cursor: cursor + 1 });
    }
  };

  submit = (search, finalSearches) => {
    const { onSubmitHandler } = this.props;
    const { searches } = this.state;
    onSubmitHandler(generateLink(search), { searches: finalSearches || searches, search });
  };

  render() {
    const { searches, isOpen, cursor, showEmptyStateSearch, isSubmitted } = this.state;
    const { searchQuery, searchHistory, isDropdownOpen } = this.props;
    const EMPTY_STATE_SEARCH_LABEL = !isEmpty(searchHistory) ? 'Recent searches' : 'Popular searches';
    const NO_MATCHES = get(searches, '[0].dataType') === DATA_TYPE.NOT_FOUND;

    const searchesWithSpeculation = searches?.filter(({ withSpeculation }) => withSpeculation);
    const searchesWithSpeculationPrerender = searchesWithSpeculation?.slice(0, 1);
    const searchesWithSpeculationPrefetch = searchesWithSpeculation?.slice(1);

    return (
      <Fragment>
        {isOpen && (
          <div className="auto-complete-container bg-white" hidden={!isDropdownOpen}>
            {cursor < 0 && isSubmitted && searchQuery && !NO_MATCHES && (
              <div className="px-1 mt-0_5 mb-0_5 text-danger medium">Please select a result below</div>
            )}
            {showEmptyStateSearch && <AutoCompleteOptionLabel label={EMPTY_STATE_SEARCH_LABEL} />}
            {!!searches?.length && (
              <Fragment>
                {searches.map((search, i) => {
                  const { dataType, index, autoSuggestValue, autoSuggestValueFull } = search;
                  const searchIndex = index !== undefined ? index : i;

                  if (NO_MATCHES) {
                    return <NoMatchesFoundLabel key={autoSuggestValueFull} label={autoSuggestValue} />;
                  }

                  return dataType === DATA_TYPE.LABEL ? (
                    <AutoCompleteOptionHighlightedLabel
                      key={autoSuggestValueFull}
                      search={search}
                      query={searchQuery}
                    />
                  ) : (
                    <AutoCompleteOption
                      key={autoSuggestValueFull}
                      search={search}
                      query={searchQuery}
                      onSubmitHandler={this.submit}
                      isActive={cursor === searchIndex ? 'auto-complete-active' : ''}
                      highlighted={dataType === DATA_TYPE.STATIC_LINK}
                    />
                  );
                })}
                {isDropdownOpen && (
                  <Fragment>
                    {!!searchesWithSpeculationPrerender?.length && (
                      <SpeculationRule
                        speculationTimeout={750}
                        urls={searchesWithSpeculationPrerender.map(generateLink)}
                      />
                    )}
                    {!!searchesWithSpeculationPrefetch?.length && (
                      <SpeculationRule
                        action="prefetch"
                        speculationTimeout={1250}
                        urls={searchesWithSpeculationPrefetch.map(generateLink)}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

export const stateToPropsConfig = {
  autoCompleteOptions: bindToPath(
    ({ searchQuery, isMobile }) => getSearchQueryPath(searchQuery, TOP_RESULT_COUNTS.MULTI, isMobile),
    AutoSuggestModel,
    autoSuggestData => generateMultipleAutoSuggest(autoSuggestData.recordTypes)
  ),
};

export const HomeVehicleSearchAutoCompleteMulti = connectToModel(HomeVehicleSearchAutoCompleteUI, stateToPropsConfig);
