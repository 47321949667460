import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

export function handleInsiderMenuOpen(isMobile = false, eventDataOverrides = {}) {
  const eventObj = {
    event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
    event_data: {
      action_name: TrackingConstant.ACTION_WIDGET_VIEW,
      action_cause: isMobile ? TrackingConstant.ACTION_CAUSE_DRAWER_OPEN : TrackingConstant.ACTION_CAUSE_MENU_OPEN,
      creative_id: isMobile ? 'nav-drawer' : 'edm-entry-global-nav',
      ...eventDataOverrides,
    },
  };

  EventToolbox.fireTrackAction(eventObj);
}
