import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';
import { PriceDropAlerts } from 'site-modules/shared/components/profile/price-drop-alerts/price-drop-alerts';
import edmundsLogo from 'client/images/logo-white.svg';
import edmundsLogo226 from 'client/images/edmunds-logo-226.png';

function renderLogoImg(useLogo226) {
  return (
    <img
      src={useLogo226 ? edmundsLogo226 : edmundsLogo}
      alt="Edmunds Homepage"
      width={useLogo226 ? 157 : null}
      height={useLogo226 ? 25 : null}
    />
  );
}

export function LogoSection({ smallScreen, toggleNav, isOpen, className, hideAlerts, useLogo226 }) {
  if (!smallScreen) {
    return (
      <Link to="/" className="logo-section py-1" data-tracking-id="global_nav_homepage_click">
        {renderLogoImg(useLogo226)}
      </Link>
    );
  }

  return (
    <button className={classnames('logo-section menu-btn py-1 text-left pos-r', className)} onClick={toggleNav}>
      {renderLogoImg()}
      <span
        className={classnames(
          'ml-0_5 align-middle text-white text-nowrap',
          isOpen ? 'icon-arrow-up3' : 'icon-arrow-down3'
        )}
      />
      {!hideAlerts && <PriceDropAlerts hideAlertsPerSession={isOpen} />}
    </button>
  );
}

LogoSection.propTypes = {
  smallScreen: PropTypes.bool,
  toggleNav: PropTypes.func,
  isOpen: PropTypes.bool,
  hideAlerts: PropTypes.bool,
  className: PropTypes.string,
  useLogo226: PropTypes.bool,
};

LogoSection.defaultProps = {
  smallScreen: false,
  toggleNav: null,
  isOpen: false,
  hideAlerts: false,
  className: '',
  useLogo226: false,
};
