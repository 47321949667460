import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ExperimentsPanelContentAsync } from './experiments-panel-content-async';

/**
 * Component that displays experiments with recipes to choose or a message if there is nothing to display.
 * It asynchronously loads the component contents.
 *
 * @param  {Boolean} enabled flag that defines whether to display the component or not.
 * @return {ReactElement} React element.
 */
export const Experiments = ({ enabled }) => {
  if (!enabled) {
    return null;
  }
  return <ExperimentsPanelContentAsync />;
};

/**
 * Experiments component prop types.
 *
 * @type {Object}
 */
Experiments.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

/**
 * Maps app Redux state to the Experiments component properties.
 *
 * @param  {Object} state    App redux state.
 * @return {Object}          Mapped properties.
 */
export const mapStateToProps = state => ({
  enabled: state.wtfabdebug === 'true',
});

/**
 * Main component of the experiments debug panel.
 */
export const ExperimentsPanel = connect(mapStateToProps)(Experiments);
