import { INSIDER_PATHS } from 'client/site-modules/shared/constants/profile/dashboard-paths';
import { venomHistory } from 'client/utils/history/venom-history';
import { filter, set } from 'lodash';

/**
 * Defines link to insider page on mobile and desktop.
 * @param {Boolean}  isMobile
 * @returns {String} path to insider
 */
export const getInsiderLandingLink = isMobile => INSIDER_PATHS[isMobile ? 'INSIDER' : 'INVENTORY_PAGE'];

/**
 * Redirects to dashboard if user is not on insider/* pages
 * or if page equals /insider/sign-up
 * @param {Object} location
 */
export const dashboardOpen = (location, isMobile = false) => {
  if (location) {
    const pathname = location.pathname || '';
    const isSignUpPage = pathname.includes(INSIDER_PATHS.SIGN_UP_PAGE);
    const isInsiderPage = pathname.includes(INSIDER_PATHS.INSIDER);
    const redirectPath = getInsiderLandingLink(isMobile);

    if (!isInsiderPage || isSignUpPage) {
      venomHistory.push(redirectPath);
    }
  }
};

/**
 * Redirects to provided path or the Insider landing page.
 * @param {String} [path]
 * @param {Boolean} isMobile
 */
export const redirect = ({ path, isMobile = false }) => {
  const redirectPath = path || getInsiderLandingLink(isMobile);
  venomHistory.push(redirectPath);
};

/**
 * Filters out nonVin and vins without savedTs
 * @param {Object} vins - idm.vehicles.vins
 * @returns {Array}
 */
export const filterUserVins = vins =>
  filter(vins, (vinData, vin) => {
    set(vinData, 'vin', vin);
    return !!vins[vin].savedTs && !vins[vin].nonVin;
  });

/**
 * @param {Object} vins - idm.vehicles.vins
 * @param {Number} itemsPerPage
 * @returns {Number} pages count
 */
export const countTotalPages = (vins, itemsPerPage = 9) => Math.ceil(vins.length / itemsPerPage);

/**
 * @param {Array} arr with data as objects
 * @param {String} id identifier of item to remove
 * @param {String} fieldName identifier name
 * @returns {Array} new array with removed value
 */
export const removeItem = (arr, id, fieldName = 'vin') => arr.filter(item => item[fieldName] !== id);

/**
 * Filters out vehicle keys with nonVin flag set to true.
 * @param {Object} vehicles
 * @returns {Array} with filtered vehicles keys
 */
export const filterNonVinVehicleKeys = vehicles => Object.keys(vehicles).filter(vinKey => !vehicles[vinKey].nonVin);
