import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { RandomModel, RandomPaths } from 'client/data/models/random';
import { PageModel } from 'client/data/models/page';
import { getQuery } from 'client/utils/location';
import { PAGE_NAMES } from 'site-modules/shared/constants/multi-offer/offers';
import { MarketingSurveyAsync } from 'site-modules/shared/components/marketing-survey/marketing-survey-async';
import { ExitSurveyAsync } from 'site-modules/shared/components/exit-survey/exit-survey-async';
import { BrandAssociationSurveyAsync } from './brand-association-survey/brand-association-survey-async';

const PAGE_NAME_EXCLUDE_LIST = [
  'five_second_test',
  'new_model_highlights',
  'sitemap',
  PAGE_NAMES.OFFER_RESULTS_NOT_FOUND,
  'car_inventory_ddp_simplified_service_reviews_form',
  'car_inventory_ddp_simplified_sales_reviews_form',
  'car_inventory_ddp_service_reviews_full',
  'car_inventory_ddp_sales_reviews_full',
  'car_inventory_ddp_map',
  'car_inventory_ddp_map_instant_offer',
  'dealercenter_products',
];

export function PageSurveysUI({ randomRecipe, location, pageName, disablePageSurveys, isMobile }) {
  const { 'page-survey': forcedRecipe, forceDefault } = getQuery(location);

  const surveyRecipe = forcedRecipe || randomRecipe;
  const isEnabled =
    forceDefault !== 'true' && !disablePageSurveys && !!surveyRecipe && !PAGE_NAME_EXCLUDE_LIST.includes(pageName);

  useEffect(() => {
    if (isEnabled) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_VIEW_CONTENT,
          subaction_name: 'page_survey_recipe',
          action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          creative_id: 'page-surveys',
          value: surveyRecipe,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);

  if (!isEnabled) {
    return null;
  }

  return (
    <Fragment>
      {surveyRecipe === 'chal-csat' && <ExitSurveyAsync pageName={pageName} isMobile={isMobile} />}
      {surveyRecipe === 'chal-brandassoc' && <BrandAssociationSurveyAsync pageName={pageName} isMobile={isMobile} />}
      {surveyRecipe === 'chal-mktg' && <MarketingSurveyAsync />}
    </Fragment>
  );
}

PageSurveysUI.propTypes = {
  randomRecipe: PropTypes.string,
  location: PropTypes.shape({}),
  pageName: PropTypes.string,
  isMobile: PropTypes.bool,
  disablePageSurveys: PropTypes.bool,
};

PageSurveysUI.defaultProps = {
  randomRecipe: null,
  location: null,
  pageName: null,
  isMobile: false,
  disablePageSurveys: false,
};

const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
  disablePageSurveys: !!get(state, 'featureFlags["disable-page-surveys"]'),
});

export const PageSurveys = connect(mapStateToProps)(
  connectToModel(PageSurveysUI, {
    randomRecipe: bindToPath(RandomPaths.getPageSurveyRecipePath, RandomModel),
    location: bindToPath('location', PageModel),
  })
);
