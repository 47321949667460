import React from 'react';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';

export function LeaderboardNative(props) {
  return (
    <FeatureFlag name="dfpProgrammaticLeader">
      {enabled => (
        <div className={enabled ? 'dfp-prog-leader' : ''}>
          <NativeAd adNameWired="leaderc" nativeStyle="leaderboard" noRefresh={false} showLabel wiredOnly {...props} />
        </div>
      )}
    </FeatureFlag>
  );
}
