import { useRef, useEffect, useCallback } from 'react';

export const useTimeout = () => {
  const timeout = useRef();

  const set = useCallback((callback, time) => {
    timeout.current = setTimeout(callback, time);
  }, []);

  const clear = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, []);

  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );

  return [set, clear];
};
