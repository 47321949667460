import PropTypes from 'prop-types';
import { set } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { transformSearchQuery } from 'site-modules/shared/utils/vehicle-search-utils';

export const MAX_SEARCH_TEXT_LENGTH = 100;

const AutoSuggestSearchResults = PropTypes.PropTypes.arrayOf(
  PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.string,
    type: PropTypes.string,
  })
);

export const getSearchQueryPath = (query, resultsCount, isMobile) => {
  const transformedSearchQuery = transformSearchQuery(query);
  return transformedSearchQuery
    ? `autoSuggest.${isMobile ? resultsCount.mobile : resultsCount.desktop}.searchText.${transformedSearchQuery.slice(
        0,
        MAX_SEARCH_TEXT_LENGTH
      )}`
    : '';
};

export const AutoSuggestModel = createModelSegment('autoSuggest', [
  /**
   * Auto suggest API. Returns results that are based on local inventory counts.
   * Example: https://qa-21-www.edmunds.com/gateway/api/inventory/v5/autosuggest?top=10&text=Honda&Accord&zip=90404&radius=50&sitemap=true&recordType=true
   * If no results were found, than suggest national results (without zip and radius)
   * Example (no results): https://qa-21-www.edmunds.com/gateway/api/inventory/v5/autosuggest?top=5&text=ferrari&zip=58102&radius=100
   * Example (national results): https://qa-21-www.edmunds.com/gateway/api/inventory/v5/autosuggest?top=5&text=ferrari
   * @see getSearchQueryPath
   * @return InventoryEntities.AutoSuggestSearch
   */
  {
    path: 'autoSuggest.{top}.searchText.{searchText}',
    resolve({ top, searchText }, context) {
      const requestUrl = `/inventory/v5/autosuggest?top=${top}&text=${searchText.split(',').join('%20')}&trims=true`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(`${requestUrl}`)
        .then(result => {
          set(result, 'query', searchText);
          return result;
        })
        .catch(() => ({ recordTypes: { makemodel: [], bodytype: [] }, query: searchText }));
    },
    update({ method }, match, context) {
      return method === 'CLEAR' ? Promise.resolve(undefined) : this.resolve(match, context);
    },
  },
]);

export const AutoSuggestEntities = {
  AutoSuggestSearchResults,
};
