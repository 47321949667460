import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CmsEntities } from 'client/data/models/cms';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { TrackingConstant } from 'client/tracking/constant';
import { EventToolbox } from 'client/utils/event-toolbox';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';

import './animated-leaderboard.scss';

const CREATIVE_ID = 'homepage_footer';

export function AnimatedLeaderboard({ content, isMobile }) {
  const ref = useRef();

  const clickCta = useCallback(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_WEBSITE_CLICKOUT,
        subaction_name: TrackingConstant.OEM_SITE_CLICKOUT,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        creative_id: CREATIVE_ID,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        value: 'Build & Price',
      },
    });
  }, []);

  const clickInventory = useCallback(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_VIEW_CONTENT,
        subaction_name: TrackingConstant.ACTION_VIEW_CONTENT,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        creative_id: CREATIVE_ID,
        value: 'Learn more',
      },
    });
  }, []);

  const addLoadedClass = () => {
    if (ref.current) ref.current.closest('div').classList.add('loaded');
  };

  useEffect(() => {
    function onLoad() {
      addLoadedClass();
    }
    if (!isMobile) window.addEventListener('load', onLoad, false);
    return () => window.removeEventListener('load', onLoad, false);
  }, [isMobile]);

  useEffect(() => {
    function onScroll() {
      addLoadedClass();
      window.removeEventListener('scroll', onScroll, false);
    }
    if (isMobile) {
      window.addEventListener('scroll', onScroll, false);
    }
    return () => window.removeEventListener('scroll', onScroll, false);
  }, [isMobile]);

  const headline = content.metadata('headline').value();
  const subheadline = content.metadata('subheadline').value();
  const ctaCopy = content.metadata('ctaCopy').value();
  const ctaUrl = content.metadata('ctaUrl').value();
  const ctaTarget = content.metadata('ctaUrlTarget').value('_blank');
  const inventoryCopy = content.metadata('inventoryCopy').value();
  const inventoryUrl = content.metadata('inventoryUrl').value();
  const inventoryUrlTarget = content.metadata('inventoryUrlTarget').value('_blank');
  const image = content.metadata('image').value();
  const linkDisplayUrl = content.metadata('linkDisplayUrl').value();

  return (
    <section className="animated-leaderboard d-flex mx-auto px-0_5" ref={ref}>
      <div className="ad-bug pos-a size-10">Ad</div>
      <NativeAd
        wrapperClass="d-none"
        adNameWired="hproadrunner"
        adNameMobile="mhproadrunner"
        nativeStyle="none"
        renderWhenViewable={false}
        refreshable={false}
      />
      {image && (
        <figure
          className="pos-r mb-0 ml-1_5 ml-lg-2 mr-0_75 mr-lg-1_75"
          style={{
            opacity: '0',
            transform: 'translateX(175%) scale(0.4)',
          }}
        >
          <img src={getStaticImageUrl(image)} width={isMobile ? '112' : '184'} alt="" />
        </figure>
      )}
      <header
        className="d-flex flex-column justify-content-center mr-md-0_5"
        style={{
          opacity: '0',
        }}
      >
        {headline && (
          <div
            className={classnames('headline font-weight-bold', {
              'size-14 mr-1_25': isMobile,
            })}
            style={{
              WebkitBoxOrient: 'vertical',
            }}
          >
            {headline}
          </div>
        )}
        {!isMobile && (
          <Fragment>
            {subheadline && (
              <div
                className="subheadline"
                style={{
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {subheadline}
              </div>
            )}
            {ctaUrl && linkDisplayUrl && (
              <a href={ctaUrl} target={ctaTarget} className="size-16 text-primary-darker" onClick={clickCta}>
                {linkDisplayUrl}
              </a>
            )}
          </Fragment>
        )}

        {isMobile && ctaUrl && ctaCopy && (
          <a href={ctaUrl} target={ctaTarget} className="size-14 text-primary-darker" onClick={clickCta}>
            {ctaCopy}
            <i className="icon-open_in_new size-10 ml-0_25" aria-hidden />
          </a>
        )}
      </header>
      {!isMobile && (
        <div
          className="cta-container d-flex align-items-center mr-0_5"
          style={{
            opacity: '0',
          }}
        >
          {inventoryUrl && inventoryCopy && (
            <a
              href={inventoryUrl}
              target={inventoryUrlTarget}
              className="px-0_25 px-sm-0_75 px-lg-2_5 btn btn-outline-primary-b mr-0_25 mr-lg-1_5"
              onClick={clickInventory}
            >
              <span className="font-weight-normal text-normal size-16">{inventoryCopy}</span>
            </a>
          )}
          {ctaUrl && ctaCopy && (
            <a
              href={ctaUrl}
              target={ctaTarget}
              className="px-0_25 px-sm-0_75 px-lg-3 btn btn-primary-b mr-0_25"
              onClick={clickCta}
            >
              <span className="font-weight-normal text-normal size-16">{ctaCopy}</span>
              <i className="icon-open_in_new size-12 ml-0_25" aria-hidden />
            </a>
          )}
        </div>
      )}
    </section>
  );
}

AnimatedLeaderboard.propTypes = {
  content: CmsEntities.Content.isRequired,
  isMobile: PropTypes.bool,
};

AnimatedLeaderboard.defaultProps = {
  isMobile: false,
};
