import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

function fireAction(eventData) {
  EventToolbox.fireTrackAction({
    ads: {
      disable_refresh: true,
    },
    ...eventData,
  });
}

/**
 * Fires every time user clicks auto suggested search link or submit by keyboard enter click
 *
 * @param {Object} trackingData
 * @param {Object} trackingOverride (default: {})
 */
export function fireViewSearchResult(trackingData, trackingOverride = {}) {
  const selectionType = trackingData.selectionType;
  const autocompleteResults = trackingData.autocompleteResults;
  const autocompleteLinks = trackingData.autocompleteLinks;
  const { actionCause } = trackingOverride;

  const eventData = {
    action_category: 'user',
    action_cause: actionCause || 'link_click',
    action_name: TrackingConstant.VIEW_SEARCH_RESULT,
    subaction_name: TrackingConstant.VIEW_AUTO_COMPLETE_RESULT,
    input: trackingData.input,
    value: trackingData.trackingValue,
    selection_type: selectionType,
    creative_id: trackingData.creativeId,
  };

  if (autocompleteResults) {
    eventData.autocomplete_results = autocompleteResults;
  }

  if (autocompleteLinks) {
    eventData.autocomplete_links = autocompleteLinks;
  }

  fireAction({ event_type: 'action_completed', event_data: eventData });
}

/**
 * Fires every time user clicks auto suggested search input
 *
 * @param {String} creativeId
 * @param {String} value
 */
export function fireSelectAutocomplete(creativeId, value) {
  const eventData = {
    action_category: 'user',
    action_cause: 'user_input',
    action_name: TrackingConstant.VIEW_SEARCH_RESULT,
    subaction_name: TrackingConstant.SELECT_AUTOCOMPLETE,
    creative_id: creativeId,
    value,
  };

  fireAction({ event_type: 'action_start', event_data: eventData });
}

/**
 * Fires every time receives no search results
 *
 * @param {String} searchQuery
 * @param {String} creativeId
 */
export function fireNoSearchResults(searchQuery, creativeId) {
  const eventData = {
    action_category: 'user',
    action_cause: 'user_input',
    action_name: TrackingConstant.VIEW_SEARCH_RESULT,
    subaction_name: TrackingConstant.EMPTY_AUTO_COMPLETE_RESULT,
    input: searchQuery.toLowerCase(),
    creative_id: creativeId,
  };

  fireAction({ event_type: 'action_completed', event_data: eventData });
}

/**
 * Fires every time user clicks out of the search bar without selecting a result
 *
 * @param {Object} trackingData
 */
export function fireAbandonSearchResult(trackingData) {
  const autocompleteResults = trackingData.autocompleteResults;
  const autocompleteLinks = trackingData.autocompleteLinks;
  const eventData = {
    action_category: 'system',
    action_cause: 'user_input',
    action_name: TrackingConstant.VIEW_SEARCH_RESULT,
    subaction_name: TrackingConstant.ABANDON_AUTO_COMPLETE_RESULT,
    input: trackingData.input,
    creative_id: trackingData.creativeId,
  };

  if (autocompleteResults) {
    eventData.autocomplete_results = autocompleteResults;
  }

  if (autocompleteLinks) {
    eventData.autocomplete_links = autocompleteLinks;
  }

  fireAction({ event_type: 'action_completed', event_data: eventData });
}

export function fireHomeHeroThumbnailSelect(trackingData) {
  const eventData = {
    action_category: 'user',
    action_cause: 'click',
    action_name: TrackingConstant.ACTION_VIEW_CONTENT,
    subaction_name: TrackingConstant.ACTION_VIEW_CONTENT,
    carousel_index: trackingData.currentIndex,
    carousel_prev_index: trackingData.prevIndex,
    carousel_total: trackingData.totalSlides,
    value: trackingData.value,
    creative_id: trackingData.creativeId,
  };

  fireAction({ event_type: 'action_completed', event_data: eventData });
}

export function fireHighImpactTabSelect(trackingData) {
  const eventData = {
    action_category: 'user',
    action_name: TrackingConstant.ACTION_VIEW_CONTENT,
    subaction_name: TrackingConstant.ACTION_VIEW_CONTENT,
    action_cause: trackingData.actionCause,
    carousel_index: trackingData.carouselIndex,
    carousel_prev_index: trackingData.carouselPrevIndex,
    carousel_total: trackingData.carouselTotal,
    value: trackingData.trackingValue,
    creative_id: trackingData.creativeId,
  };

  fireAction({ event_type: 'action_completed', event_data: eventData });
}

export function fireVehicleShowcaseThumbnailSelect(trackingData) {
  const eventData = {
    action_category: 'user',
    action_name: TrackingConstant.ACTION_VIEW_CONTENT,
    subaction_name: TrackingConstant.ACTION_VIEW_CONTENT,
    action_cause: trackingData.actionCause,
    carousel_index: trackingData.carouselIndex,
    carousel_prev_index: trackingData.carouselPrevIndex,
    value: trackingData.trackingValue,
    creative_id: trackingData.creativeId,
  };

  fireAction({ event_type: 'action_completed', event_data: eventData });
}
