import React from 'react';
import { Link } from 'site-modules/shared/components/link/link';

const wrapperStyle = {
  width: '100%',
  textAlign: 'center',
};

const listStyle = {
  padding: 0,
  listStyle: 'none',
  columnWidth: '300px',
};

const links = [
  {
    to: '/',
    text: 'Home',
  },
  {
    to: '/honda/accord/',
    text: '/honda/accord/',
  },
  {
    to: '/honda/accord/2022/',
    text: '/honda/accord/2022/',
  },
  {
    to: '/honda/accord/2023/',
    text: '/honda/accord/2023/',
  },
  {
    to: '/honda/accord/2018/',
    text: '/honda/accord/2018/',
  },
  {
    to: '/bmw/3-series/',
    text: '/bmw/3-series/',
  },
  {
    to: '/bmw/3-series/2022/',
    text: '/bmw/3-series/2022/',
  },
  {
    to: '/bmw/3-series/2015/',
    text: '/bmw/3-series/2015/',
  },
  {
    to: '/best-family-cars/',
    text: '/best-family-cars/',
  },
  {
    to: '/suv/articles/best-family-suv/',
    text: '/suv/articles/best-family-suv/',
  },
  {
    to: '/dealerships/volkswagen/california/gardengrove/VolkswagenofGardenGrove/',
    text: 'Volkswagen of Garden Grove',
  },
  {
    to: '/car-buying/10-steps-to-buying-a-new-car.html?enable-feature=edHighImpactAdUnit',
    text: '10 steps to buying a new car',
  },
  {
    to: '/used-cars-for-sale/',
    text: 'Used cars for sale',
  },
  {
    to: '/used-honda-accord-san-jose-ca/',
    text: 'Used Honda Accord San Jose (Flat URL)',
  },
  {
    to: '/car-comparisons/',
    text: 'Comparator',
  },
  {
    to: '/404-page/',
    text: '404 page',
  },
  {
    to: '/honda/civic/2022/trim-lx/',
    text: '404 - Honda Civic 2020',
  },
  {
    to: '/honda/civic/2022/st-401679040-lx-4dr-sedan/',
    text: '404 - Honda Civic 2020 LX 4dr Sedan',
  },
  {
    to: '/dealerships/volkswagen/california/gardengrove/VolkswagenofGardenGrovy/',
    text: '404 - Volkswagen of Garden Grovy',
  },
  {
    to: '/car-buying/121-steps-to-buying-a-new-car.html?enable-feature=edHighImpactAdUnit',
    text: '404 - 121 steps to buying a new car',
  },
  {
    to: '/honda/accord/2020/vin/1HGCV1F4XJA076921/',
    text: '404 - Vin Details Page Honda Accord 2020',
  },
  {
    to: '/honda/acord/',
    text: '404 - Model Review Honda Acord',
  },
];

export function SpaLinks() {
  return (
    <section style={wrapperStyle}>
      <h3>SPA Links</h3>
      <ul style={listStyle}>
        {links.map(({ to, text }) => (
          <li key={to}>
            <Link to={to}>{text}</Link>
          </li>
        ))}
      </ul>
    </section>
  );
}
